import styled from '@emotion/styled';
import { PropsWithChildren } from 'react';
import { keyframes } from '@emotion/react';
import { CornerBox } from '../layout';

const anim = keyframes`
  from {
    opacity: 0;
    transform: translateY(20rem) scale(0.1);
  }
  to {
    opacity: 1;
  }
`;

const Box = styled(CornerBox)<{ isMobile?: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '100%' : '62.2rem')};
    position: relative;
    top: ${({ isMobile }) => isMobile && '-40rem'};
    height: ${({ isMobile }) => (isMobile ? '15rem' : '26.4rem')};

    margin: 0 auto;
    opacity: 1;
    animation: ${anim} 500ms;
`;

type Props = PropsWithChildren & {
    className?: string;
    isMobile?: boolean;
};

const Modal = (props: Props) => {
    const { className, children, isMobile } = props;

    return (
        <Box className={className} cornerSize={'3.5rem'} isMobile={isMobile}>
            {children}
        </Box>
    );
};

export { Modal };
