import styled from '@emotion/styled';
import { useTableStateHook } from '../../hooks/use-table-state.hook';
import {
    useGameContext,
    useLogicActionContext,
    useLogicStateContext,
} from '@blackjack/ui/contexts';
import { VirtualTable } from '@blackjack/ui/components-3d';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
`;

export const VirtualTableComponent = () => {
    const {
        gameState,
        handlePlayerAction: handleRoundAction,
        muted,
    } = useGameContext();

    const { betHistory, replicateSideBets } = useLogicStateContext();

    const { handlePlayerClick, handlePlayerAction } = useLogicActionContext();

    const tableState = useTableStateHook();

    return (
        <Container>
            <VirtualTable
                state={tableState}
                players={gameState.players}
                userId={gameState.user?.id || ''}
                userBets={betHistory}
                currentTurn={gameState?.currentState?.seat_id ?? -1}
                muted={muted}
                onPlayerAction={handlePlayerAction}
                onPlayerClick={handlePlayerClick}
                onPlayerPreAction={handleRoundAction}
                cameraConfig={{
                    orbitControls: false,
                    position: {
                        x: -0.0,
                        y: 2.730863487908139,
                        z: 6.951068591837024,
                    },
                    grid: false,
                    lookAt: {
                        x: -0.0,
                        y: -0.0,
                        z: 0.0,
                    },
                    focalLength: 24,
                }}
                config={{
                    totalSeats: 7,
                    startAngle: -60,
                    endAngle: 60,
                    radius: 2.7,
                    center: {
                        x: -0.0,
                        y: 1.5,
                    },
                }}
                isMobile
                replicateSideBets={replicateSideBets}
            />
        </Container>
    );
};
