import styled from '@emotion/styled';
import { FormEvent, useState } from 'react';

import { useAspectRatioHandler } from '@shared/ui/hooks';

interface DemoCasinoProps {
    app: string;
}

const HEADER_HEIGHT = 40;

const MIN = `
            min(calc(100vw - ${HEADER_HEIGHT * 2}px),
            calc(177vh - ${HEADER_HEIGHT * 4}px))`;

const Container = styled.div`
    box-sizing: border-box;

    background-color: #09090a;

    height: 100vh;

    overflow: hidden;

    iframe {
        margin: 40px;
        box-sizing: border-box;
        width: ${MIN};
        height: calc((1080 / 1920) * ${MIN});
    }

    &.vertical {
        iframe {
            margin: 0px;
            box-sizing: border-box;
            width: 100vw;
            height: calc(100vh - ${HEADER_HEIGHT}px);
        }
    }

    font-size: 14px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100vw;
    height: ${HEADER_HEIGHT}px;
    background-color: #1f1f25;

    padding: 0px 10px;

    form {
        display: flex;
        align-items: center;
        gap: 4px;
        box-sizing: border-box;
    }

    input {
        width: 100px;
        padding: 2px 6px;
        border: 1px solid #909090;
        background: #eaeaea;
    }
`;

const createSession = (data: Record<string, unknown>) => {
    return btoa(JSON.stringify(data));
};

const getSessionUrl = (username: string, credits: number, currency: string) => {
    const token = createSession({
        username,
        credits,
        type: 'user',
        currency: currency,
    });
    return {
        url: `${window.location.origin}?session=${token}&t=${Date.now()}`,
        token,
    };
};

export const DemoCasino = (props: DemoCasinoProps) => {
    const { app } = props;

    const { isVertical } = useAspectRatioHandler();

    const [form, setForm] = useState<{
        username: string;
        credits: number;
        currency: string;
    }>({
        username: 'animo',
        credits: 10000,
        currency: 'USD',
    });

    const [gameUrl, setGameUrl] = useState(
        localStorage.getItem(`${app}_demo_casino_url`)
    );

    const updateForm = (field: 'username' | 'credits' | 'currency') => {
        return (ev: FormEvent<HTMLInputElement>) => {
            const value = ev.currentTarget.value;

            setForm((prev) => {
                return {
                    ...prev,
                    [field]: field === 'credits' ? +value : value,
                };
            });
        };
    };

    const updateUser = (ev: FormEvent) => {
        ev.preventDefault();
        const data = getSessionUrl(form.username, form.credits, form.currency);
        localStorage.setItem(`${app}_demo_casino_url`, data.url);
        localStorage.setItem(`${app}_demo_casino_session`, data.token);
        setGameUrl(data.url);
    };

    return (
        <Container className={isVertical ? 'vertical' : ''}>
            <Header>
                <form onSubmit={updateUser}>
                    <input
                        type="text"
                        placeholder="username"
                        value={form.username}
                        onChange={updateForm('username')}
                    />
                    <input
                        type="text"
                        placeholder="credits"
                        value={form.credits}
                        onChange={updateForm('credits')}
                    />
                    <input
                        type="text"
                        placeholder="currency"
                        value={form.currency}
                        onChange={updateForm('currency')}
                    />
                    <input type="submit" value="Update" />
                </form>
            </Header>
            {gameUrl && <iframe src={gameUrl} title="Animo Game" />}
        </Container>
    );
};
