import styled from '@emotion/styled';
import { CardHandMapper } from './card-hand-mapper';
import { ICard, IPlayerScore } from '@blackjack/models';
import { CornerBox } from './layout/corner-box';

const Container = styled.div<{ isMobile?: boolean }>`
    display: flex;
    flex-direction: ${({ isMobile }) =>
        isMobile ? 'column-reverse' : 'column'};
    justify-content: space-between;
    margin-top: ${({ isMobile }) => !isMobile && '1.4rem'};
    flex-shrink: 1;
`;

const HandWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    height: 100%;
`;

const HandName = styled(CornerBox)`
    padding: 0px 1.2rem 0 1.2rem;
    height: 3rem;
    color: white;
    font-size: 1.4rem;
    align-items: center;
    margin-left: auto;
    text-transform: uppercase;

    font-family: 'Chakra Petch';
    text-align: right;

    .content {
        white-space: nowrap;
    }
`;

const HandNameMobile = styled.div`
    font-size: 0.9rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    color: var(--secondary-game-ui-color);
    text-transform: uppercase;

    align-items: center;
`;

interface HandProps {
    hand: ICard[];
    score?: IPlayerScore;
    isDealer: boolean;
    isMobile?: boolean;
    cardScore?: boolean;
}

const Hand = (props: HandProps) => {
    const { hand, score, isDealer = true, isMobile, cardScore } = props;

    if (hand.length === 0) return null;
    return (
        <Container isMobile={isMobile}>
            <HandWrapper>
                <CardHandMapper
                    hand={hand}
                    score={score}
                    isMobile={isMobile}
                    cardScore={cardScore}
                />
            </HandWrapper>
            {isMobile ? (
                <HandNameMobile>
                    {isDealer ? "Dealer's Hand" : 'Your Hand'}
                </HandNameMobile>
            ) : (
                <HandName
                    bottomLeft
                    cornerSize={'1.0rem'}
                    borderRadius={'0.4rem'}
                >
                    {isDealer ? "Dealer's Hand" : 'Your Hand'}
                </HandName>
            )}
        </Container>
    );
};

export { Hand, HandProps };
