import styled from '@emotion/styled';
import { ICard, IPlayerScore } from '@blackjack/models';
import { keyframes } from '@emotion/react';
import { Card } from './cards';
import { Score } from './player';

const anim = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`;

const HandRow = styled.div<{ isMobile?: boolean; count?: number }>`
    flex-direction: row;
    display: flex;
    flex: 1;
    position: relative;

    transition: width 200ms ease-in-out;

    .card {
        margin-right: ${({ isMobile, count }) =>
            isMobile ? (count && count > 4 ? '-5.3rem' : '-4rem') : '-7rem'};
        border: 0.1rem solid rgba(0, 0, 0, 0.9);

        animation: ${anim} 250ms ease-in-out;
    }

    .card:last-child {
        margin-right: 0;
    }
    .score {
        font-size: ${({ isMobile }) => (isMobile ? '2.5rem' : '2rem')};
        position: ${({ isMobile }) => (isMobile ? 'absolute' : '')};
        top: ${({ isMobile }) => (isMobile ? 'unset' : '9rem')};
        bottom: ${({ isMobile }) => (isMobile ? '-0.9rem' : 'unset')};
        right: ${({ isMobile }) => (isMobile ? '0' : 'unset')};
        margin-right: ${({ isMobile }) => (isMobile ? '0.6rem' : '2rem')};
        z-index: 1;
    }
`;

interface CardHandMapperProps {
    score?: IPlayerScore;
    hand: ICard[];
    isMobile?: boolean;
    cardScore?: boolean;
}

const CardHandMapper = (props: CardHandMapperProps) => {
    const { hand, score, isMobile, cardScore } = props;

    return (
        <HandRow isMobile={isMobile} count={hand.length}>
            {score && (
                <Score
                    score={score}
                    isMobile={isMobile}
                    cardScore={cardScore}
                />
            )}
            {hand.map((card) => (
                <Card
                    key={card.uuid}
                    face={card.face}
                    value={card.value}
                    flipped
                    style={{
                        position: 'relative',
                    }}
                    isMobile={isMobile}
                />
            ))}
        </HandRow>
    );
};

export { CardHandMapper, CardHandMapperProps };
