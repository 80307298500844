import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import {
    SettingButton as ChatSettingsButton,
    SettingButton as AudioSettingsButton,
    SettingButton as OtherSettingsButton,
    SettingButton as HistorySettingsButton,
    SettingButton as InfoSettingsButton,
    SettingButton as FullScreenSettingsButton,
} from './buttons/setting-button';
import { SettingsModal } from './settings/settings-modal';

import { ICONS } from './icons';
import { SettingMenuItems } from './settings/settings.type';

const Container = styled.div<{ isMobile?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: end;
`;

const ButtonContainer = styled.div<{ isMobile?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    height: 4rem;
    z-index: 1;
    pointer-events: auto;
    color: var(--color-primary);

    // Adds spacing between buttons without adding to first button or the nested modal
    & > * + *:not(.modal) {
        margin-left: 1rem;
    }
`;

export interface SettingsButtonRowProps {
    muted: boolean;
    toggleMute: React.Dispatch<React.SetStateAction<boolean>>;
    onSettingsClick?: () => void;
    onMuteClick?: () => void;
    audioRef?: React.RefObject<HTMLAudioElement> | null;
    isMobile?: boolean;
    volume?: number;
    setVolume?: React.Dispatch<React.SetStateAction<number>>;
}

export const SettingsButtonsRow = ({
    muted,
    toggleMute,
    onSettingsClick,
    audioRef = null, // TODO temp optional to avoid conflict with games that dont use base-layout yet
    isMobile,
    volume = 20,
    setVolume = () => {
        console.log('temp fix');
    },
}: SettingsButtonRowProps) => {
    const [activeSetting, setActiveSetting] = useState<SettingMenuItems | null>(
        null
    );
    const [isFullScreen, setIsFullScreen] = useState(
        document.fullscreenElement
    );

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };

    useEffect(() => {
        document.addEventListener('fullscreenchange', () => {
            setIsFullScreen(document.fullscreenElement);
        });
    }, []);

    const handleClick = (setting: SettingMenuItems) => {
        if (activeSetting === setting) return setActiveSetting(null);
        setActiveSetting(setting);
    };

    return (
        <Container isMobile={isMobile}>
            <ButtonContainer>
                <ChatSettingsButton
                    icon={ICONS.Message}
                    onClick={() => handleClick(SettingMenuItems.CHAT)}
                />

                <AudioSettingsButton
                    icon={muted ? ICONS.Mute : ICONS.Unmute}
                    onClick={() => handleClick(SettingMenuItems.AUDIO)}
                />

                <OtherSettingsButton
                    icon={ICONS.Setting}
                    onClick={onSettingsClick}
                />

                <HistorySettingsButton
                    icon={ICONS.BackArrow}
                    onClick={() => handleClick(SettingMenuItems.HISTORY)}
                />

                <InfoSettingsButton
                    icon={ICONS.Info}
                    onClick={() => handleClick(SettingMenuItems.INFO)}
                />

                <FullScreenSettingsButton
                    icon={isFullScreen ? ICONS.Minimize : ICONS.Fullscreen}
                    onClick={toggleFullScreen}
                />

                <SettingsModal
                    activeSetting={activeSetting}
                    isMobile={isMobile ?? false}
                    audioRef={audioRef}
                    muted={muted}
                    setMuted={() => toggleMute((prev) => !prev)}
                    volume={volume}
                    setVolume={setVolume}
                />
            </ButtonContainer>
        </Container>
    );
};
