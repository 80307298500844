import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as Streak } from '../../assets/icons/Streak.svg';

export interface WinStreakProps {
    amount: number;
}

const anim = keyframes`
  from, to {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }
`;

const Container = styled.div`
    position: relative;
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    background-size: contain;
    background-repeat: no-repeat;

    transition: background-image 500ms ease-in-out;

    box-sizing: border-box;

    animation: ${anim} 1s;

    .streak {
        position: absolute;
    }

    .value {
        position: relative;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`;

export const WinStreak = (props: WinStreakProps) => {
    return (
        <Container
            key={`gem-${props.amount}`}
            className={`win-streak gem-${props.amount > 6 ? 6 : props.amount}`}
        >
            <Streak className="streak" />
            <div className="value">{props.amount}</div>
        </Container>
    );
};
