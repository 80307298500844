import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div<{ disabled: boolean }>`
    display: flex;
    justify-content: center;

    svg {
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
        opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
        text {
            user-select: none;
        }
    }

    transition: filter 100ms ease-in;

    &.active:hover {
        filter: url(#fill-texture);
    }
`;

export interface SvgButtonProps {
    children: React.ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    className: string;
}

export const SvgButton = (props: SvgButtonProps) => {
    const { children, onClick, disabled = false, className } = props;

    return (
        <Container
            className={`${className} ${disabled ? 'disabled' : 'active'}`}
            onClick={disabled ? undefined : onClick}
            disabled={disabled}
        >
            {children}
        </Container>
    );
};
