import styled from '@emotion/styled';

import { ReactComponent as DoubleLarge } from '../../assets/buttons/Double_Button_Large.svg';
import { ReactComponent as HitLarge } from '../../assets/buttons/Hit_Button.svg';
import { ReactComponent as StandLarge } from '../../assets/buttons/Stand_Button.svg';
import { ReactComponent as SplitLarge } from '../../assets/buttons/Split_Button_Large.svg';

import { ReactComponent as DoubleSmall } from '../../assets/buttons/Small_Double_Button.svg';
import { ReactComponent as SmallHit } from '../../assets/buttons/Small_Hit_Button.svg';
import { ReactComponent as SmallStand } from '../../assets/buttons/Small_Stand_Button.svg';
import { ReactComponent as SplitSmall } from '../../assets/buttons/Small_Split_Button.svg';

import { ReactComponent as MobileHitButton } from '../../assets/buttons/Mobile_Hit_Button.svg';
import { ReactComponent as MobileStandButton } from '../../assets/buttons/Mobile_Stand_Button.svg';
import { ReactComponent as MobileDoubleButton } from '../../assets/buttons/Mobile_Double_Button.svg';
import { ReactComponent as MobileSplitButton } from '../../assets/buttons/Mobile_Split_Button.svg';

import { SvgButton } from './svg-button';
import { PlayerAction } from '@blackjack/models';
import { CSSProperties } from 'react';
import clsx from 'clsx';

const Container = styled.div<{ isMini: boolean }>`
    width: ${({ isMini }) => isMini && '10.1rem'};
    height: ${({ isMini }) => isMini && '2.5rem'};

    display: flex;
    flex-direction: row;
    pointer-events: auto;

    gap: ${({ isMini }) => (isMini ? '0.3rem' : '2rem')};
    align-items: center;
    z-index: 1;

    .hit-stand-button-large {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1.1rem;
    }

    .hit-stand-button-small {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 0.3rem;
    }

    .side-button-large {
        width: 11rem;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hit-button-large {
        width: 16.2rem;
        height: 7.4rem;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            font-size: 1rem;
        }
    }

    .side-button-small {
        width: 2.1rem;
        height: 2.1rem;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hit-button-small {
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const MobileContainer = styled.div<{ isMini: boolean }>`
    width: ${({ isMini }) => isMini && '10.1rem'};
    height: ${({ isMini }) => isMini && '2.5rem'};

    display: flex;
    flex-direction: row;

    pointer-events: auto;

    gap: ${({ isMini }) => (isMini ? '0.3rem' : '2rem')};
    position: ${({ isMini }) => isMini && 'relative'};
    top: ${({ isMini }) => isMini && '2rem'};
    align-items: center;
    z-index: 1;

    .hit-stand-button-large {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1.1rem;
    }

    .hit-stand-button-small {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 0.3rem;
    }

    .side-button-large {
        width: 6.5rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 0.8rem;
    }

    .hit-button-large {
        width: 9.5rem;
        height: 4.3rem;

        border-radius: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            font-size: 1rem;
        }
    }

    .side-button-small {
        width: 2.1rem;
        height: 2.1rem;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hit-button-small {
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export interface HitButtonRowProps {
    seatId: number;
    availablePlayerAction: PlayerAction[];
    handlePlayerAction: (
        action: PlayerAction,
        seatId: number,
        isPreAction: boolean
    ) => void;
    isMini?: boolean;
    style?: CSSProperties;
    isMobile?: boolean;
}

export const HitButtonRow = (props: HitButtonRowProps) => {
    const {
        isMini = false,
        seatId,
        availablePlayerAction,
        handlePlayerAction,
        isMobile,
    } = props;

    const isActive = (action: PlayerAction) => {
        return availablePlayerAction.includes(action);
    };

    const buttonClass = isMini ? 'small' : 'large';

    return isMobile ? (
        <MobileContainer isMini={isMini}>
            <SvgButton
                onClick={() =>
                    handlePlayerAction(PlayerAction.DOUBLE, seatId, isMini)
                }
                disabled={!isActive(PlayerAction.DOUBLE)}
                className={`side-button-${buttonClass}`}
            >
                {isMini ? <DoubleSmall /> : <MobileDoubleButton />}
            </SvgButton>
            <div
                className={clsx(
                    isMini ? 'hit-stand-button-small' : 'hit-stand-button-large'
                )}
            >
                <SvgButton
                    onClick={() =>
                        handlePlayerAction(PlayerAction.HIT, seatId, isMini)
                    }
                    disabled={!isActive(PlayerAction.HIT)}
                    className={`hit-button-${buttonClass}`}
                >
                    {isMini ? <SmallHit /> : <MobileHitButton />}
                </SvgButton>
                <SvgButton
                    onClick={() =>
                        handlePlayerAction(PlayerAction.STAND, seatId, isMini)
                    }
                    disabled={!isActive(PlayerAction.STAND)}
                    className={`hit-button-${buttonClass}`}
                >
                    {isMini ? <SmallStand /> : <MobileStandButton />}
                </SvgButton>
            </div>
            <SvgButton
                onClick={() =>
                    handlePlayerAction(PlayerAction.SPLIT, seatId, isMini)
                }
                disabled={!isActive(PlayerAction.SPLIT)}
                className={`side-button-${buttonClass}`}
            >
                {isMini ? <SplitSmall /> : <MobileSplitButton />}
            </SvgButton>
        </MobileContainer>
    ) : (
        <Container isMini={isMini}>
            <SvgButton
                onClick={() =>
                    handlePlayerAction(PlayerAction.DOUBLE, seatId, isMini)
                }
                disabled={!isActive(PlayerAction.DOUBLE)}
                className={`side-button-${buttonClass}`}
            >
                {isMini ? <DoubleSmall /> : <DoubleLarge />}
            </SvgButton>
            <div
                className={clsx(
                    isMini ? 'hit-stand-button-small' : 'hit-stand-button-large'
                )}
            >
                <SvgButton
                    onClick={() =>
                        handlePlayerAction(PlayerAction.HIT, seatId, isMini)
                    }
                    disabled={!isActive(PlayerAction.HIT)}
                    className={`hit-button-${buttonClass}`}
                >
                    {isMini ? <SmallHit /> : <HitLarge />}
                </SvgButton>
                <SvgButton
                    onClick={() =>
                        handlePlayerAction(PlayerAction.STAND, seatId, isMini)
                    }
                    disabled={!isActive(PlayerAction.STAND)}
                    className={`hit-button-${buttonClass}`}
                >
                    {isMini ? <SmallStand /> : <StandLarge />}
                </SvgButton>
            </div>
            <SvgButton
                onClick={() =>
                    handlePlayerAction(PlayerAction.SPLIT, seatId, isMini)
                }
                disabled={!isActive(PlayerAction.SPLIT)}
                className={`side-button-${buttonClass}`}
            >
                {isMini ? <SplitSmall /> : <SplitLarge />}
            </SvgButton>
        </Container>
    );
};
