// External
import { useEffect } from 'react';

// Internal
import { createCurrencyConverter } from '@shared/currency-convertor';
import { useGameContext, useLogicStateContext } from '@blackjack/ui/contexts';
import { useChipContext } from '@shared/ui/contexts';
import { useAspectRatioHandler, AspectRatioWrapper } from '@shared/ui/hooks';

import backgroundImage from '@shared/assets/images/UI_DEMO_EXAMPLE_1.png';

// Local
import { DesktopAppLayout } from './app-layout-desktop';
import { MobileAppLayout } from './app-layout-mobile';

import '../../main.css';

const currencyConverter = createCurrencyConverter();

export const AppLayout = () => {
    const { isVertical } = useAspectRatioHandler();

    const { replicateSideBets } = useLogicStateContext();

    const { updateUserSettings, gameState } = useGameContext();

    const { setConversionRate } = useChipContext();

    useEffect(() => {
        updateUserSettings(replicateSideBets);
    }, [replicateSideBets, updateUserSettings]);

    useEffect(() => {
        if (gameState.user?.currency) {
            // TODO: Change base rate to use games base currency
            setConversionRate(
                currencyConverter.convert('USD', gameState.user?.currency, 1)
            );
        }
    }, [setConversionRate, gameState.user]);

    return (
        <AspectRatioWrapper
            isVertical={isVertical}
            backgroundImage={backgroundImage}
        >
            {isVertical ? <MobileAppLayout /> : <DesktopAppLayout />}
        </AspectRatioWrapper>
    );
};
