import { IPlayerCard } from './card.type';
import { PerfectPairs, TwentyOnePlus } from './payout.type';
import { ICard, PlayerAction, PlayerState } from '@shared/models';

export enum PlayerBet {
    BASE = 'base',
    BEHIND = 'behind',
    PERFECT_PAIRS = 'perfect_pairs',
    TWENTY_ONE = 'twenty_one',
    INSURANCE = 'insurance',
}

export enum PlayerBetMeta {
    DOUBLE = 'double',
    SPLIT = 'split',
}

export type IPlayerWins = {
    [key in Exclude<PlayerBet, PlayerBet.BEHIND>]: number;
};

export type IPlayerBets = {
    [key in Exclude<PlayerBet, PlayerBet.BEHIND>]: number;
};

export interface PlayerPayout {
    player_id: string;
    seat: number;
    hand: number;
    type: PlayerBet;
    bet: number;
    payout: number;
    multiplier: number;
}

export interface IPlayerScore {
    min: number;
    max: number;
    text: string;
    blackjack: boolean;
}

export type PlayerData = {
    user_id: string;
    name: string;
    seat: number;
    scores: IPlayerScore[];
    bets: IPlayerBets;
    betBehinds: { user_id: string; amount: number }[];
    chips: {
        insurance: number;
        double: number;
        split: number;
    };
    winAmounts: IPlayerWins;
    winStreak: number;
    lastAction: PlayerAction | null;
    lastActionTime: number | null;
    lastActions: [PlayerAction | null, PlayerAction | null];
    lastActionTimes: [number | null, number | null];
    isFinished: boolean;
    isPlaying: boolean;
    cards: ICard[][];
    state: PlayerState;
    states: [PlayerState, PlayerState];
};

export interface IBetBehind {
    user_id: string;
    amount: number;
}

export interface IBlackJackPlayerState {
    cards: IPlayerCard[]; // All of players cards
    firstCards: IPlayerCard[]; // Players first 2 cards used for side bets
    handActions: [PlayerAction[], PlayerAction[]]; // Actions played for each hand
    bets: IPlayerBets; // Amounts for each bet type
    winAmounts: IPlayerWins; // Win amounts for base and side bets
    behindBets: IBetBehind[]; // All users who bet behind on this seat
    sideBets: {
        // Side bet win reason
        perfectPairs: PerfectPairs;
        twentyOne: TwentyOnePlus;
    };
    isDisconnected: boolean; // If their websocket is disconnected
    winStreak: number; // Total wins in a row for this seat
    state: [PlayerState, PlayerState]; // Current state of player for both hands
    lockedIn: boolean; // If the players bet is locked in
    lastActionTimes: [number | null, number | null]; // Last time the player made an action
    hasInsurance: boolean;
}

export interface BlackJackPlayerState extends IBlackJackPlayerState {
    id: string;
    name: string;
    seat: number;
}

export type IBlackJackPlayer = {
    id: string;
    name: string;
    seat: number;

    cards: () => IPlayerCard[];
    actions: () => PlayerAction[];
    actionsForHand: (hand: number) => PlayerAction[];
    isPlaying: () => boolean;
    isFinished: () => boolean;
    hasInsurance: () => boolean;
    canSplit: () => boolean;
    hasSplit: () => boolean;
    hasDoubled: () => boolean;
    isLockedIn: () => boolean;
    lockIn: () => void;
    score: (hand?: number) => IPlayerScore;

    addCard: (card: IPlayerCard) => void;
    addCards: (cards: IPlayerCard[]) => void;
    addAction: (action: PlayerAction) => void;
    addBet: (amount: number, type: PlayerBet, user_id?: string) => void;
    clearCards: () => void;
    clearState: () => void;
    cardsForHand: (hand: number) => ICard[];
    getCurrentHand: () => number;
    getPlayerActions: (dealerCard: ICard) => PlayerAction[];
    clone: () => IBlackJackPlayer;
    getData: () => BlackJackPlayerState;
    setData: (data: Partial<IBlackJackPlayerState>) => void;
    setDisconnected: (isDisconnected: boolean) => void;
    setHasInsurance: (hasInsurance: boolean) => void;
    toData: () => PlayerData;
    updatePlayerState: (newState: PlayerState, hand?: number) => void;
    incrementWinStreak: () => void;
    clearWinStreak: () => void;
};
