import { useGameLobbyContext } from '@game-lobby/ui/contexts';
import { useCallback, useEffect } from 'react';

export enum GlobalMessage {
    CLOSE_IFRAME = 'close-iframe',
}

export interface GlobalMessageData {
    message: GlobalMessage;
}

const allowedOrigin = 'http://localhost:4200'; // TODO use .env

export const useSendGlobalMessage = () => {
    const postMessage = useCallback((data: GlobalMessageData) => {
        window.parent.postMessage(data, allowedOrigin);
    }, []);

    return postMessage;
};

export const useListenGlobalMessage = (
    callback: (data: GlobalMessageData) => void
) => {
    const {
        gameLobbyState: { allowedGameUrls },
    } = useGameLobbyContext();

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            // Block requests without a valid gameUrl
            if (allowedGameUrls.includes(event.origin)) {
                callback(event.data);
            } else {
                console.warn(
                    `Blocked message from untrusted origin: ${event.origin}`
                );
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [callback, allowedGameUrls]);
};
