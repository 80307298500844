import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { BlackJackStates } from '@blackjack/models';
import { useGameContext } from '@blackjack/ui/contexts';

import { Chip } from '@shared/ui/components/chips';

import Chip1 from '@shared/assets/audio/1chip.mp3';
import Chip5 from '@shared/assets/audio/5chip.mp3';
import Chip10 from '@shared/assets/audio/10chip.mp3';
import Chip25 from '@shared/assets/audio/25chip.mp3';
import Chip100 from '@shared/assets/audio/100chip.mp3';
import Chip500 from '@shared/assets/audio/500chip.mp3';

const Container = styled.div<{ isExpanded: boolean; chipCount: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .chips {
        position: relative;
        width: 7.8rem;
        height: 7.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform 0.6s ease-in-out;

        .chip {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            transition: transform 0.6s ease-in-out, opacity 0.3s ease-in-out,
                z-index 0.3s;

            .chip-wrapper {
                box-sizing: border-box;
                border: 0.2rem solid transparent;
                border-radius: 50%;
            }

            &.selected {
                z-index: 10;
                transform: translate(0, 0);
                position: relative;
                margin: 0;
            }

            ${({ chipCount, isExpanded }) => {
                const radius = 12;
                const angleIncrement = Math.PI / (chipCount - 1);
                return Array.from({ length: chipCount }, (_, index) => {
                    const angle = index * angleIncrement;
                    const x = Math.cos(angle) * radius;
                    const y = Math.sin(angle) * radius;

                    return `
                        &:nth-of-type(${index + 1}) {
                            transform: ${
                                isExpanded
                                    ? `translate(${x}rem, ${-y}rem)`
                                    : 'translate(0, 0)'
                            };
                        }
                    `;
                }).join('');
            }}

            &:not(.selected) {
                z-index: 1;
            }
        }
    }

    .selected-chip-area {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 15;

        svg {
            height: ${({ isExpanded }) => (isExpanded ? '8rem' : '9.1rem')};
            width: 8.8rem;
            transition: height 250ms ease-in-out;
        }
    }

    svg .hover {
        opacity: 0;
    }
`;

export interface MobileChipSelectorProps {
    betAmounts: number[];
    activeChip: number | null;
    totalCredits: number;
    setActiveChip: (amount: number | null) => void;
    isMobile?: boolean;
}

// sufficient number of chips sounds to match number of chips
const CHIP_SOUNDS = [
    Chip1,
    Chip5,
    Chip10,
    Chip25,
    Chip25,
    Chip100,
    Chip500,
    Chip500,
];

export const MobileChipRow = (props: MobileChipSelectorProps) => {
    const { betAmounts, activeChip, totalCredits, setActiveChip, isMobile } =
        props;
    const { gameState } = useGameContext();

    const [isExpanded, setIsExpanded] = useState(false);

    const containerRef = useRef<HTMLDivElement>(null);

    const disabled = (amount: number) => {
        if (
            gameState.currentState.next_state !== BlackJackStates.WaitingForBets
        ) {
            return true;
        }
        if (amount > totalCredits) return true;
        if (gameState.gameInfo && amount < gameState?.gameInfo?.min_bet)
            return true;
        return false;
    };

    const handleClick = (amount: number, idx: number) => {
        return () => {
            if (disabled(amount)) return;

            const sound = new Audio(CHIP_SOUNDS[idx]);
            sound.volume = 0.4;
            sound.play();

            if (activeChip === amount) {
                setActiveChip(null);
            } else {
                setActiveChip(amount);
            }

            setIsExpanded(!isExpanded);
        };
    };

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target as Node)
            ) {
                setIsExpanded(false);
            }
        };

        if (isExpanded) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isExpanded]);

    return (
        <Container
            isExpanded={isExpanded}
            chipCount={betAmounts.length - 1}
            ref={containerRef}
        >
            <div className="chips">
                {betAmounts
                    .filter((amount) => amount !== activeChip)
                    .reverse()
                    .map((amount, idx) => (
                        <div
                            key={`chip-${amount}`}
                            className={clsx('chip', {
                                selected: amount === activeChip,
                                disabled: disabled(amount),
                            })}
                            onClick={handleClick(amount, idx)}
                        >
                            <div className="chip-wrapper">
                                <Chip
                                    size={`medium`}
                                    value={amount}
                                    disabled={disabled(amount)}
                                    isMobile={isMobile}
                                />
                            </div>
                        </div>
                    ))}
            </div>
            {activeChip && (
                <div
                    className="selected-chip-area"
                    onClick={() => {
                        gameState.currentState.next_state ===
                            BlackJackStates.WaitingForBets &&
                            setIsExpanded(!isExpanded);
                    }}
                >
                    <div
                        className="chip-wrapper"
                        style={{ height: '9.1rem', width: '8.8rem' }}
                    >
                        <Chip
                            size={'x-large'}
                            value={activeChip}
                            // disabled={disabled(activeChip)}
                            isMobile={isMobile}
                        />
                    </div>
                </div>
            )}
        </Container>
    );
};
