import { BlackJackStates, GameState } from '@blackjack/models';
import { useEffect, useState } from 'react';

export const useGameMessageHook = (
    gameState: GameState,
    totalSeats: number,
    isReady: boolean
) => {
    const [gameMessage, setGameMessage] = useState({
        show: false,
        message: '',
    });

    useEffect(() => {
        const checkSeated = gameState.players.filter(
            (p) => p.user_id === gameState.user?.id
        );

        const betPlayer = gameState.roundInfo?.players?.filter(
            (p) => p.user_id === gameState.user?.id
        );

        //Displays a popup when user joins the table.
        if (
            checkSeated.length === 0 &&
            gameState.players.length <= totalSeats &&
            gameState.currentState.next_state !==
                BlackJackStates.WaitingForBets &&
            isReady
        ) {
            setGameMessage({
                show: true,
                message: 'Please take a seat.',
            });
        } else {
            setGameMessage({ show: false, message: '' });
        }

        // If player is kicked from the table
        if (gameState.kickedFromSeat.isKicked) {
            setGameMessage({
                show: true,
                message:
                    'You are kicked from the table. You can place a Bet Behind',
            });
        }

        //Displays a popup if all the seats are pre occupied.
        if (
            checkSeated.length === 0 &&
            gameState.players.length === totalSeats
        ) {
            setGameMessage({
                show: true,
                message:
                    'All the seats are filled. Please wait till next round.',
            });
        }

        //Displays a popup if player join the session in the middle of the game.
        if (
            checkSeated.length > 0 &&
            betPlayer?.length === 0 &&
            gameState.currentState.next_state !==
                BlackJackStates.FinishedBetting &&
            gameState.currentState.next_state !== BlackJackStates.GameEnd &&
            gameState.currentState.next_state !== BlackJackStates.WaitingForBets
        ) {
            setGameMessage({
                show: true,
                message: 'Game in progress. Please wait till next round.',
            });
        }
    }, [
        gameState.kickedFromSeat.isKicked,
        gameState.players,
        gameState.user,
        gameState.roundInfo,
        gameState.currentState,
        totalSeats,
        isReady,
    ]);

    return gameMessage;
};
