import styled from '@emotion/styled';
import { Timer } from '../timer';
import { HitButtonRow } from '../buttons';
import { PlayerAction } from '@blackjack/models';
import { keyframes } from '@emotion/react';

const anim = keyframes`
  from {
    opacity: 0;
    transform: translateY(20rem) scale(0.1);
  }
  to {
    opacity: 1;
  }
`;

const mobileAnim = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const MobileContainer = styled.div`
    height: 11.7rem;
    width: 35.3rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 1;

    pointer-events: auto;

    position: relative;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    transistion: opacity 300ms ease-in;

    animation: ${mobileAnim} 500ms ease-in-out;

    .timer {
        height: 4.5rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5rem;
    }

    h2 {
        height: 1.9rem;
        width: 35.3rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        line-height: normal;

        color: #ffffff;
        margin-top: 0.5rem;
    }
`;

const Container = styled.div`
    width: 59.5rem;
    height: 18rem;

    display: grid;
    margin: 0 auto;

    position: relative;
    top: 6rem;

    align-items: center;
    pointer-events: auto;

    animation: ${anim} 500ms;

    .timer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
    }

    h2 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        text-transform: uppercase;
        font-size: 1.7rem;
        font-weight: 600;
        line-height: 2.2rem;

        color: #ffffff;
        margin-top: 2rem;
    }
`;

export interface PlayerActionModalProps {
    actions: PlayerAction[];
    seatId: number;
    endTime: number;
    handlePlayerAction: (
        action: PlayerAction,
        seatId: number,
        isPreAction?: boolean
    ) => void;
    isMobile?: boolean;
}

export const PlayerActionModal = (props: PlayerActionModalProps) => {
    const { actions, seatId, endTime, handlePlayerAction, isMobile } = props;

    return !isMobile ? (
        <Container>
            <div className="timer">
                <Timer endTime={endTime} />
            </div>
            <div>
                {actions && (
                    <HitButtonRow
                        seatId={seatId}
                        handlePlayerAction={handlePlayerAction}
                        availablePlayerAction={actions}
                    />
                )}
            </div>
            <div>
                <h2>Make your decision</h2>
            </div>
        </Container>
    ) : (
        <MobileContainer>
            <div className="timer">
                <Timer endTime={endTime} />
            </div>
            {actions && (
                <HitButtonRow
                    seatId={seatId}
                    handlePlayerAction={handlePlayerAction}
                    availablePlayerAction={actions}
                    isMobile={isMobile}
                />
            )}
            <div>
                <h2>Make your decision</h2>
            </div>
        </MobileContainer>
    );
};
