import styled from '@emotion/styled';
import { WinStreak } from './win-streak';

const Name = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    margin-top: auto;

    font-family: 'Enduro SemiBold';
    color: white;
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;

    gap: 0.3rem;
    z-index: 9;
    .win-streak {
        // margin-left: -2rem;
    }
`;

export interface PlayerStreakProps {
    className?: string;
    name: string;
    winStreak: number;
}

export const PlayerStreak = (props: PlayerStreakProps) => {
    const { name, winStreak, className = '' } = props;

    return (
        <Name className={className}>
            {winStreak > 0 && <WinStreak amount={winStreak} />}
            {name}
        </Name>
    );
};
