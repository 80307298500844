import {
    BlackJackStates,
    PlayerAction,
    PlayerData,
    PlayerState,
    RoundAction,
} from '@blackjack/models';
import styled from '@emotion/styled';
import { CSSProperties } from 'react';
import { HitButtonRow } from '../buttons';
import { Score, PlayerStreak } from '../player';

type playerDataTypeModified = {
    lastAction: PlayerData['lastAction'];
    lastActions: PlayerData['lastActions'];
    lastActionTimes: PlayerData['lastActionTimes'];
    cards: PlayerData['cards'];
    scores: PlayerData['scores'];
    baseBet: PlayerData['bets']['base'];
    playerState: PlayerData['state'];
    states: PlayerData['states'];
    seat: PlayerData['seat'];
    playerName: PlayerData['name'];
    winStreak: PlayerData['winStreak'];
};

export interface PlayerNameProps {
    player: playerDataTypeModified;
    state: BlackJackStates;
    preAction?: RoundAction;
    ownSeat: boolean;
    isUserGameLeader: boolean;
    kickPlayerhandler: (seatId: number) => void;
    hand: number;
    selected: boolean;
    style?: CSSProperties;
    onClick: () => void;
    handlePlayerAction: (
        action: PlayerAction,
        seatId: number,
        isPreAction?: boolean
    ) => void;
    isMobile?: boolean;
}

const Container = styled.div`
    width: 24rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-family: 'Chakra Petch';
    user-select: none;
    box-sizing: border-box;

    height: 7rem;

    pointer-events: none;

    .name {
        display: flex;
        flex-direction: row;

        align-items: center;
        margin-top: auto;
        color: white;
        font-size: 2rem;
        text-transform: uppercase;

        gap: 1rem;

        .win-streak {
            margin-left: -3rem;
        }
    }

    .scores {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.4rem;
    }
    .section {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1.2rem;

        color: white;

        font-family: 'Chakra Petch';

        pointer-events: auto;

        &.button {
            width: 3.6rem;
            height: 3.6rem;

            border-radius: 50%;
            cursor: pointer;
            user-select: none;

            font-size: 2.2rem;
            line-height: 2.4rem;
            background-color: rgba(0, 0, 0, 0.8);

            transition: background-color 200ms ease-in-out;

            &.kick {
                background-color: orange;
            }
            &:hover {
                background-color: #ee2f25;
            }
        }
    }
`;

const MobileContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    font-family: 'Chakra Petch';
    user-select: none;
    box-sizing: border-box;

    z-index: 9999;

    width: 2rem;
    height: 7rem;

    pointer-events: none;

    .name {
        display: flex;
        flex-direction: row;

        align-items: center;
        margin-top: auto;
        color: white;
        font-size: 2rem;
        text-transform: uppercase;

        gap: 1rem;

        .win-streak {
            margin-left: -3rem;
        }
    }

    .scores {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.4rem;
    }

    .section {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;

        font-size: 1.2rem;

        color: white;

        font-family: 'Chakra Petch';

        pointer-events: auto;

        &.button {
            width: 2rem;
            height: 2rem;

            border-radius: 50%;
            cursor: pointer;
            user-select: none;

            font-size: 1.5rem;
            line-height: 2.4rem;
            background-color: rgba(0, 0, 0, 0.8);

            transition: background-color 200ms ease-in-out;

            &:hover {
                background-color: #ee2f25;
            }
        }
    }
`;

export const PlayerName = (props: PlayerNameProps) => {
    const {
        player,
        isUserGameLeader,
        kickPlayerhandler,
        state,
        preAction,
        ownSeat,
        selected,
        hand,
        onClick,
        handlePlayerAction,
        style,
        isMobile,
    } = props;

    const {
        lastAction,
        lastActions,
        lastActionTimes,
        cards,
        scores,
        baseBet,
        playerState,
        states,
        seat,
        playerName,
        winStreak,
    } = player;

    const shouldRenderHitUI =
        !selected &&
        preAction &&
        (lastAction === null ||
            lastAction === PlayerAction.BET ||
            lastAction === PlayerAction.INSURANCE) &&
        cards[0].length === 2 &&
        !scores[0].blackjack;

    const canLeave =
        ownSeat && (baseBet === 0 || state === BlackJackStates.WaitingForBets);

    return isMobile ? (
        <MobileContainer
            style={style}
            className={selected ? 'selected' : 'not-selected'}
        >
            {canLeave && (
                <div className="section button leave" onClick={onClick}>
                    x
                </div>
            )}
            {isUserGameLeader && !ownSeat && (
                <div
                    className="section button kick"
                    onClick={() => kickPlayerhandler(seat)}
                >
                    x
                </div>
            )}
            {scores[0].max > 0 && (
                <div className="scores">
                    {scores.length > 1 &&
                        playerState !== PlayerState.WAITING &&
                        scores[1].min > 0 && (
                            <Score
                                score={scores[1]}
                                selected={selected && hand === 1}
                                lastAction={lastActions[1]}
                                updated={lastActionTimes[1]}
                                playerState={states[1]}
                                isMobile={isMobile}
                            />
                        )}
                    <Score
                        score={scores[0]}
                        selected={selected && hand === 0}
                        lastAction={lastAction}
                        updated={lastActionTimes[0]}
                        playerState={playerState}
                        isMobile={isMobile}
                    />
                </div>
            )}
            {shouldRenderHitUI ? (
                <HitButtonRow
                    seatId={seat}
                    availablePlayerAction={preAction.actions}
                    handlePlayerAction={handlePlayerAction}
                    isMini
                    isMobile={isMobile}
                />
            ) : (
                <PlayerStreak name={playerName} winStreak={winStreak} />
            )}
        </MobileContainer>
    ) : (
        <Container
            style={style}
            className={selected ? 'selected' : 'not-selected'}
        >
            {canLeave && (
                <div className="section button leave" onClick={onClick}>
                    x
                </div>
            )}
            {isUserGameLeader && !ownSeat && (
                <div
                    className="section button kick"
                    onClick={() => kickPlayerhandler(seat)}
                >
                    x
                </div>
            )}
            {scores[0].max > 0 && (
                <div className="scores">
                    {scores.length > 1 &&
                        playerState !== PlayerState.WAITING &&
                        scores[1].min > 0 && (
                            <Score
                                score={scores[1]}
                                selected={selected && hand === 1}
                                lastAction={lastActions[1]}
                                updated={lastActionTimes[1]}
                                playerState={states[1]}
                            />
                        )}
                    <Score
                        score={scores[0]}
                        selected={selected && hand === 0}
                        lastAction={lastAction}
                        updated={lastActionTimes[0]}
                        playerState={playerState}
                    />
                </div>
            )}
            {shouldRenderHitUI ? (
                <HitButtonRow
                    seatId={seat}
                    availablePlayerAction={preAction.actions}
                    handlePlayerAction={handlePlayerAction}
                    isMini
                />
            ) : (
                <PlayerStreak name={playerName} winStreak={winStreak} />
            )}
        </Container>
    );
};
