import { useEffect } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { ImUndo2 } from 'react-icons/im';
import { IoRepeat } from 'react-icons/io5';

import {
    useGameContext,
    useLogicStateContext,
    useLogicActionContext,
} from '@blackjack/ui/contexts';
import { BlackJackStates } from '@blackjack/models';

import { ChipRowButton } from '@shared/ui/components/chips';

import { MobileChipRow } from './mobile-chip-row';
import { DealCardsButton } from '../buttons/deal-cards-button';

const anim = keyframes`
  from {
    opacity: 0;
    transform: translateY(20rem) scale(0.1);
  }
  to {
    opacity: 1;
  }
`;

const slide = keyframes`
    from {
        transform: translateY(10rem) scale(0.1);
    }
    to {
        transform: translateY(0);
    }
`;

const Container = styled.div`
    width: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: auto;

    padding-bottom: 1.7rem;

    .place-your-bets {
        position: absolute;
        top: 38%;

        animation: ${anim} 500ms;
        width: 100%;

        h2 {
            text-transform: uppercase;
            color: #fff;

            text-align: center;
            font-family: 'Chakra Petch';
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .button-interaction-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
    }

    .button-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        gap: 3.9rem;
    }
`;

const DealCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    animation: ${slide} 500ms;

    margin-top: 1rem;
    position: relative;
    top: -1rem;

    .clicky {
        pointer-events: auto;
        cursor: pointer;
        user-select: none;
        background: var(--blackjack-side-button);
    }

    .content {
        padding: 1rem 2rem;
        color: white;
        font-family: Chakra Petch;
        font-size: 1.4rem;
    }
`;

interface MobileChipSelectorProps {
    isMobile?: boolean;
}

export const MobileChipSelector = ({ isMobile }: MobileChipSelectorProps) => {
    const {
        totalCredits,
        activeChip,
        canRepeat,
        userTotalSeats,
        baseBetTotal,
        sideBetTotals,
    } = useLogicStateContext();

    const { setActiveChip, undoBet, repeatLast, doubleBets } =
        useLogicActionContext();

    const { gameState, handlePlayerDealNow } = useGameContext();

    const betAmounts = gameState.gameInfo?.bet_amounts || [];

    const canDealNow =
        gameState.players.every((p) => p.user_id === gameState?.user?.id) &&
        baseBetTotal > 0;

    const canDouble =
        (gameState.user?.balance || 0) >=
            (baseBetTotal * userTotalSeats + sideBetTotals) * 2 &&
        baseBetTotal * 2 <= (gameState.gameInfo?.max_bet || 0);

    const undoButtonDisabled =
        gameState.currentState.next_state !== BlackJackStates.WaitingForBets ||
        baseBetTotal === 0;

    const repeatButtonDisabled =
        gameState.currentState.next_state !== BlackJackStates.WaitingForBets ||
        !canRepeat;

    useEffect(() => {
        if (!activeChip) {
            setActiveChip(gameState.gameInfo?.min_bet as number);
        }
    }, []);

    return (
        <Container>
            <div className="button-interaction-row">
                <div className="button-wrapper">
                    <ChipRowButton
                        onClickHandler={undoBet}
                        disabled={undoButtonDisabled}
                        text={!isMobile ? 'UNDO' : ''}
                        svg={isMobile ? <ImUndo2 /> : undefined}
                    />
                    <MobileChipRow
                        betAmounts={betAmounts}
                        activeChip={activeChip}
                        totalCredits={totalCredits}
                        setActiveChip={setActiveChip}
                    />
                    {baseBetTotal > 0 ? (
                        <ChipRowButton
                            onClickHandler={doubleBets}
                            disabled={undoButtonDisabled || !canDouble}
                            text={'X2'}
                        />
                    ) : (
                        <ChipRowButton
                            onClickHandler={repeatLast}
                            disabled={repeatButtonDisabled}
                            svg={<IoRepeat />}
                        />
                    )}
                </div>
            </div>
            {gameState.currentState.next_state ===
                BlackJackStates.WaitingForBets && (
                <div className="place-your-bets">
                    <h2>Place Your Bets</h2>
                    {canDealNow && (
                        <DealCardsContainer>
                            <DealCardsButton onClick={handlePlayerDealNow} />
                        </DealCardsContainer>
                    )}
                </div>
            )}
        </Container>
    );
};
