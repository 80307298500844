import styled from '@emotion/styled';
import { ReactNode } from 'react';

const Container = styled.div<{ leftSide: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    min-width: 11rem;
    height: 5rem;

    padding: 0 0.7rem 0 0.7rem;

    background: var(--blackjack-opaque-background);

    border: 0.15rem solid var(--primary-border-color);
    border-radius: 1rem;

    font-family: 'Chakra Petch', sans-serif;

    .button-title {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.56rem;
        text-transform: uppercase;
        color: var(--secondary-game-ui-color);
    }

    .button-value {
        font-size: 1.9rem;
        font-weight: 600;
        line-height: 2.47rem;
        color: var(--primary-game-ui-color);
    }
`;

interface Props {
    leftSide?: boolean;
    title: string;
    children: ReactNode;
}

const BalanceBetButtons = (props: Props) => {
    const { leftSide = false, title, children } = props;

    return (
        <Container leftSide={leftSide}>
            <span className="button-title">{title}</span>
            <span className="button-value">{children}</span>
        </Container>
    );
};

export { BalanceBetButtons, Props };
