import { PlayerBet, PlayerData, UserBet } from '@blackjack/models';

export const getUserBets = (
    player: PlayerData | undefined,
    userBets: UserBet[],
    ownSeat: boolean,
    seatId: number
) => {
    return userBets.reduce<UserBet[]>((bets, userBet) => {
        if (player && ownSeat) {
            const playerBet = player.bets;

            Object.entries(playerBet).forEach(([betType, value]) => {
                if (userBet.type === betType && value) {
                    bets.push(userBet);
                }
            });
        } else if (
            userBet.type === PlayerBet.BEHIND &&
            userBet.seat === seatId
        ) {
            bets.push(userBet);
        }

        return bets;
    }, []);
};
