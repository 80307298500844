import styled from '@emotion/styled';
import { Timer } from '../timer';
import { SvgButton } from '../buttons';
import { GameContextProps } from '@blackjack/ui/contexts';

import { ReactComponent as InsuranceYes } from '../../assets/icons/Insurance_yes.svg';
import { ReactComponent as InsuranceNo } from '../../assets/icons/Insurance_no.svg';

import { ReactComponent as MobileInsuranceYes } from '../../assets/icons/Mobile_Insurance_Yes.svg';
import { ReactComponent as MobileInsuranceNo } from '../../assets/icons/Mobile_Insurance_No.svg';

import { keyframes } from '@emotion/react';

const mobileAnim = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
    width: 33.5rem;
    height: 10.8rem;
    margin: 0 auto;

    position: relative;
    top: 10rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: auto;

    .timer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 2rem;
    }

    h2 {
        text-transform: uppercase;
        font-size: 1.7rem;
        font-weight: 600;
        line-height: normal;
        color: #ffffff;
        margin: 1.2rem;
    }

    .button-wrapper {
        display: flex;
        flex-direction: row;

        align-items: center;

        gap: 1.1rem;

        .button {
            height: 5rem;
        }
    }
`;

const MobileContainer = styled.div`
    height: 11.7rem;
    width: 35.3rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    pointer-events: auto;

    animation: ${mobileAnim} 500ms ease-in-out;

    z-index: 1;

    position: relative;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);

    .timer {
        height: 4.5rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h2 {
        height: 2.2rem;
        width: 10.4rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        text-transform: uppercase;
        font-size: 1.7rem;
        font-weight: 600;
        font-style: normal;
        text-align: center;
        line-height: normal;

        color: #ffffff;
        margin: 0.5rem;
    }

    .button-wrapper {
        display: flex;
        flex-direction: row;

        align-items: center;

        gap: 1.1rem;

        .button {
            height: 5rem;
        }
    }
`;

interface Props {
    endTime: number;
    handlePlayerInsurance: GameContextProps['handlePlayerInsurance'];
    isMobile?: boolean;
}

const Insurance = (props: Props) => {
    const { endTime, handlePlayerInsurance, isMobile } = props;

    return isMobile ? (
        <MobileContainer>
            <div className="timer">
                <Timer endTime={endTime} />
            </div>
            <h2>Insurance?</h2>
            <div className="button-wrapper">
                <SvgButton
                    className="button"
                    onClick={() => handlePlayerInsurance(true)}
                >
                    <MobileInsuranceYes />
                </SvgButton>
                <SvgButton
                    className="button"
                    onClick={() => handlePlayerInsurance(false)}
                >
                    <MobileInsuranceNo />
                </SvgButton>
            </div>
        </MobileContainer>
    ) : (
        <Container>
            <div className="timer">
                <Timer endTime={endTime} />
            </div>
            <h2>Insurance?</h2>
            <div className="button-wrapper">
                <SvgButton
                    className="button"
                    onClick={() => handlePlayerInsurance(true)}
                >
                    <InsuranceYes />
                </SvgButton>
                <SvgButton
                    className="button"
                    onClick={() => handlePlayerInsurance(false)}
                >
                    <InsuranceNo />
                </SvgButton>
            </div>
        </Container>
    );
};

export { Insurance };
