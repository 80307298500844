import {
    IPlayerBets,
    IPlayerWins,
    PlayerAction,
    PlayerBet,
} from '@blackjack/models';
import { useSpring } from '@react-spring/three';
import { Html, useTexture } from '@react-three/drei';
import React, { useCallback } from 'react';
import { degToRad } from 'three/src/math/MathUtils';
import { AnimatedShape } from './animated-shape';
import { Chips } from './chips';
import { ClickableShape } from './clickable-shape';

import styled from '@emotion/styled';
import insuranceIcon from '../../assets/icons/insurance-icon.png';

const InsuranceWrapper = styled(Html)`
    width: 2rem;
    margin-left: 1.8rem;
    img {
        width: 2rem;
    }
`;

export interface SeatProps extends React.PropsWithChildren {
    seat?: number;
    position?: [number, number, number];
    rotation?: [number, number, number];
    seated?: boolean;
    selected?: boolean;
    mode?: 'EDIT' | 'LOCKED' | 'SIDEBETS' | 'BASE' | 'CLEAR';
    ownSeat?: boolean;

    isKicked?: boolean;

    isPlayerAtSeatLimit: boolean;

    bets: {
        uuid: string;
        amount: number;
        type: PlayerBet;
        seat: number;
    }[];

    insurance: number;

    behindAmount?: number;
    totalBehind?: number;
    behindWinAmount?: number;
    betAmounts?: IPlayerBets;
    winAmounts?: IPlayerWins;

    muted?: boolean;

    onClick?: (action: PlayerAction, type: PlayerBet) => void;

    isMobile?: boolean;
    replicateSideBets?: boolean;
}

const DESIRED_SIZE = 0.5;

const BASE_SIZE = 1.35;
const SIDE_SIZE = 0.9;

const ROTATION: [number, number, number] = [degToRad(90), 0, 0];

const SeatComponent = (props: SeatProps) => {
    const {
        seat = -1,
        position = [0, 0, 0],
        rotation = [0, 0, 0],
        seated = false,
        ownSeat = false,
        isKicked = false,
        isPlayerAtSeatLimit,
        bets = [],
        betAmounts = {
            base: 0,
            perfect_pairs: 0,
            twenty_one: 0,
            behind: 0,
        },
        winAmounts = {
            base: 0,
            twenty_one: 0,
            perfect_pairs: 0,
            insurance: 0,
        },
        mode = 'LOCKED',
        selected = false,
        insurance = 0,
        muted = false,
        onClick,
    } = props;

    const handleClick = useCallback(
        (type: PlayerAction, section: PlayerBet) => {
            return () => {
                if (onClick) {
                    onClick(type, section);
                }
            };
        },
        [onClick]
    );

    const overallOpacity = seated ? 0.7 : 0.3;
    const seatColor = seated ? '#ffffff' : '#ffffff';

    const { opacity } = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 },
        reverse: !(
            (selected && mode !== 'EDIT') ||
            (seated && ownSeat && mode === 'EDIT')
        ),
    });

    return (
        <group
            position={position}
            rotation={rotation}
            receiveShadow={true}
            scale={1.1}
        >
            <object3D rotation={ROTATION}>
                <object3D scale={props.isMobile ? [1, 1, 1] : [1, 1, 1]}>
                    <Html center position={[0, 0.41, 0]} zIndexRange={[15, 0]}>
                        {props.children}
                    </Html>
                    <group name="base" receiveShadow={true} dispose={null}>
                        {seated || isKicked ? (
                            <AnimatedShape
                                key={'base'}
                                texturePath={
                                    './assets/table/user-table-base.png'
                                }
                                opacity={overallOpacity}
                                color={seatColor}
                                scale={0.3}
                            />
                        ) : (
                            <AnimatedShape
                                key={'join'}
                                texturePath={'./assets/table/vacant-seat.png'}
                                opacity={overallOpacity}
                                color={seatColor}
                                pulsate
                                scale={0.2}
                            />
                        )}
                        <ClickableShape
                            color={'rgba(100,220,255, 1.0)'}
                            type={'circle'}
                            args={{ radius: DESIRED_SIZE / 2 }}
                            opacity={1}
                            disabled={
                                (seated && !ownSeat) ||
                                (mode !== 'EDIT' && seated) ||
                                isKicked ||
                                (isPlayerAtSeatLimit && !ownSeat)
                            }
                            onClick={handleClick(
                                seated ? PlayerAction.BET : PlayerAction.SIT,
                                PlayerBet.BASE
                            )}
                        />
                        <Chips
                            type={PlayerBet.BASE}
                            seat={seat}
                            seated={seated}
                            ownSeat={ownSeat}
                            bets={bets.filter((b) => b.type === PlayerBet.BASE)}
                            amount={betAmounts.base}
                            winAmount={winAmounts.base}
                            mode={mode}
                            scale={BASE_SIZE}
                            position={[0, 0, 0]}
                            rotateZ={rotation[1]}
                            muted={muted}
                        />
                    </group>
                    <group name="perfect-pairs">
                        <AnimatedShape
                            texturePath={
                                './assets/table/user-table-perfect-pairs.png'
                            }
                            opacity={overallOpacity}
                            color={seatColor}
                            scale={0.5}
                        />
                        <ClickableShape
                            type={'arc'}
                            color={'rgba(100,220,255, 1.0)'}
                            disabled={
                                !seated ||
                                !ownSeat ||
                                mode !== 'EDIT' ||
                                bets.length === 0
                            }
                            args={{
                                innerRadius: DESIRED_SIZE / 3,
                                outerRadius: DESIRED_SIZE / 1.75,
                                segments: 32,
                                startAngle: 180 - 30,
                                length: 70,
                            }}
                            opacity={0}
                            onClick={handleClick(
                                PlayerAction.BET,
                                PlayerBet.PERFECT_PAIRS
                            )}
                        />
                        <Chips
                            type={PlayerBet.PERFECT_PAIRS}
                            seat={seat}
                            seated={seated}
                            ownSeat={ownSeat}
                            bets={bets.filter((b) =>
                                props.replicateSideBets
                                    ? b.type === PlayerBet.PERFECT_PAIRS
                                    : b.type === PlayerBet.PERFECT_PAIRS &&
                                      b.seat === seat
                            )}
                            amount={betAmounts.perfect_pairs}
                            winAmount={winAmounts.perfect_pairs}
                            mode={mode}
                            scale={SIDE_SIZE}
                            position={[-0.25, 0, -0.0]}
                            rotateZ={rotation[1]}
                            muted={muted}
                        />
                    </group>
                    <group name="twenty-one" receiveShadow={true}>
                        <AnimatedShape
                            texturePath={'./assets/table/user-table-213.png'}
                            opacity={overallOpacity}
                            color={seatColor}
                            scale={0.5}
                        />
                        <ClickableShape
                            type={'arc'}
                            color={'rgba(100,220,255, 1.0)'}
                            args={{
                                innerRadius: DESIRED_SIZE / 3,
                                outerRadius: DESIRED_SIZE / 1.75,
                                segments: 32,
                                startAngle: 320,
                                length: 70,
                            }}
                            disabled={
                                !seated ||
                                !ownSeat ||
                                mode !== 'EDIT' ||
                                bets.length === 0
                            }
                            onClick={handleClick(
                                PlayerAction.BET,
                                PlayerBet.TWENTY_ONE
                            )}
                            opacity={0}
                        />
                        <Chips
                            type={PlayerBet.TWENTY_ONE}
                            seat={seat}
                            seated={seated}
                            ownSeat={ownSeat}
                            bets={bets.filter((b) =>
                                props.replicateSideBets
                                    ? b.type === PlayerBet.TWENTY_ONE
                                    : b.type === PlayerBet.TWENTY_ONE &&
                                      b.seat === seat
                            )}
                            amount={betAmounts.twenty_one}
                            winAmount={winAmounts.twenty_one}
                            mode={mode}
                            scale={SIDE_SIZE}
                            position={[0.25, 0, -0.0]}
                            rotateZ={rotation[1]}
                            muted={muted}
                        />
                    </group>
                    <group name="bet-behind">
                        <AnimatedShape
                            texturePath={'./assets/table/user-table-behind.png'}
                            opacity={overallOpacity}
                            color={seatColor}
                            scale={0.5}
                        />
                        <ClickableShape
                            type={'arc'}
                            color={'rgba(100,220,255, 1.0)'}
                            args={{
                                innerRadius: DESIRED_SIZE / 3,
                                outerRadius: DESIRED_SIZE / 1.75,
                                segments: 32,
                                startAngle: 45,
                                length: 90,
                            }}
                            disabled={!seated || ownSeat || mode !== 'EDIT'}
                            opacity={0}
                            onClick={handleClick(
                                PlayerAction.BET,
                                PlayerBet.BEHIND
                            )}
                        />
                        <Chips
                            type={PlayerBet.BEHIND}
                            seat={seat}
                            seated={seated}
                            ownSeat={!ownSeat}
                            bets={bets.filter(
                                (b) =>
                                    b.type === PlayerBet.BEHIND &&
                                    seat === b.seat
                            )}
                            amount={props.behindAmount || 0}
                            winAmount={props.behindWinAmount || 0}
                            mode={mode}
                            scale={SIDE_SIZE}
                            position={[0, 0.26, 0.0]}
                            rotateZ={rotation[1]}
                            muted={muted}
                        />
                    </group>
                    {insurance > 0 && (
                        <group
                            name="insurance"
                            scale={SIDE_SIZE}
                            position={[0.1, -0.2, 0.0]}
                        >
                            {mode !== 'CLEAR' && (
                                <InsuranceWrapper
                                    position={[0.0, -0, 0]}
                                    center
                                    zIndexRange={[15, 0]}
                                >
                                    <img
                                        src={insuranceIcon}
                                        alt={'Insurance'}
                                    />
                                </InsuranceWrapper>
                            )}
                            <Chips
                                type={PlayerBet.INSURANCE}
                                seat={seat}
                                seated={seated}
                                ownSeat={ownSeat}
                                bets={[]}
                                amount={insurance}
                                winAmount={winAmounts.insurance}
                                mode={mode}
                                muted={muted}
                                scale={SIDE_SIZE}
                                position={[0, 0, 0.0]}
                                rotateZ={rotation[1]}
                            />
                        </group>
                    )}
                </object3D>
            </object3D>
        </group>
    );
};

useTexture.preload('./assets/table/user-table-behind.png');
useTexture.preload('./assets/table/user-table-base.png');
useTexture.preload('./assets/table/user-table-join.png');
useTexture.preload('./assets/table/user-table-213.png');
useTexture.preload('./assets/table/user-table-perfect-pairs.png');

export const Seat = React.memo(SeatComponent);
