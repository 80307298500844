import styled from '@emotion/styled';

import { CornerBox } from '@blackjack/ui/components';
import { keyframes } from '@emotion/react';

const anim = keyframes`
  from {
    opacity: 0;
    transform:translateY(20rem) scale(0.1);
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
    position: fixed;
    z-index: 10001;

    display: flex;
    align-items: center;
    justify-content: center;

    top: 0rem;
    width: 192rem;
    height: 108rem;

    pointer-events: none;

    animation: ${anim} 500ms ease-in-out;

    &.hide {
        display: none;
    }

    .form {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 4rem 4rem;

        pointer-events: none;

        .button {
            height: 5rem;
        }
    }

    .message {
        font-size: 2rem;
    }
`;

const MobileContainer = styled.div`
    position: absolute;
    z-index: 10001;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    top: -10%;

    pointer-events: none;

    animation: ${anim} 500ms ease-in-out;

    &.hide {
        display: none;
    }

    .form {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 4rem 4rem;

        pointer-events: none;

        .button {
            height: 5rem;
        }
    }

    .message {
        font-size: 2rem;
    }
`;

const Wrapper = styled.div<{ isMobile?: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '30rem' : '45rem')};
`;

export interface CustomPopUpProps {
    isVisible: boolean;
    onClose: () => void;
    message: string;
    isMobile?: boolean;
}

export const CustomPopUp = (props: CustomPopUpProps) => {
    const { isVisible, onClose, message, isMobile = false } = props;

    return isMobile ? (
        <MobileContainer
            onClick={onClose}
            className={isVisible ? 'show' : 'hide'}
        >
            <Wrapper isMobile={props.isMobile}>
                <CornerBox
                    topRight
                    bottomLeft
                    topLeft
                    bottomRight
                    cornerSize={'2rem'}
                >
                    <div className="form">
                        <span className="message">{message}</span>
                    </div>
                </CornerBox>
            </Wrapper>
        </MobileContainer>
    ) : (
        <Container onClick={onClose} className={isVisible ? 'show' : 'hide'}>
            <Wrapper isMobile={props.isMobile}>
                <CornerBox
                    topRight
                    bottomLeft
                    topLeft
                    bottomRight
                    cornerSize={'2rem'}
                >
                    <div className="form">
                        <span className="message">{message}</span>
                    </div>
                </CornerBox>
            </Wrapper>
        </Container>
    );
};
