import * as ReactDOM from 'react-dom/client';

import { BaseAppLayout, Themes } from '@shared/ui/layouts';
import {
    GameContextProvider,
    LogicContextProvider,
} from '@blackjack/ui/contexts';

import { AppLayout } from './app/layouts/app-layout';

import './sentry.config';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <BaseAppLayout name="blackjack" theme={Themes.GOLD}>
        <GameContextProvider>
            <LogicContextProvider>
                <AppLayout />
            </LogicContextProvider>
        </GameContextProvider>
    </BaseAppLayout>
);
