import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';

export interface TimerProps {
    endTime: number;
    onFinish?: () => void;
    isMobile?: boolean;
}

const Container = styled.div<{ endTime: number | null }>`
    display: flex;
    align-items: center;
    justify-content: center;

    visibility: ${({ endTime }) => (endTime ? 'visible' : 'hidden')};

    width: 7rem;
    height: 7rem;

    position: relative;
`;

const anim = keyframes`
  0% {
    font-size: 1rem;
    opacity: 1;
  }
  30%, 70% {
    font-size: 5rem;
  }
  70% {
    opacity: 1;
  }
  100% {
    font-size: 1rem;
    opacity: 0;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    font-size: 5rem;
  } to {
    font-size: 1rem;
    opacity: 0;
  }
`;

const Number = styled.div<{ isMobile?: boolean }>`
    font-size: ${({ isMobile }) => (isMobile ? '3rem' : '5rem')};
    font-family: 'Chakra Petch', sans-serif;
    font-weight: 600;
    color: #fff;

    &.animated {
        font-size: 1rem;
        animation: ${anim} 1000ms;
    }

    &.number-0 {
        animation: ${fadeOut} 1000ms;
        opacity: 0;
    }
`;

export const Timer = (props: TimerProps) => {
    const { endTime, isMobile } = props;
    const timerRef = useRef<ReturnType<typeof setTimeout>>();

    const [remaining, setRemaining] = useState(
        Math.round(endTime - Date.now())
    );

    useEffect(() => {
        const updateTimer = () => {
            timerRef.current = setTimeout(() => {
                const re = endTime - Date.now();
                setRemaining(Math.max(0, re));

                if (re >= 0) {
                    updateTimer();
                }
            }, 100);
        };

        if (endTime) {
            updateTimer();
        }

        return () => {
            clearTimeout(timerRef.current);
        };
    }, [endTime]);

    const countdown = Math.ceil(remaining / 1000);

    const className = `number-${countdown} ${countdown < 4 ? 'animated' : ''}`;

    return (
        <Container endTime={endTime}>
            <Number className={className} key={countdown} isMobile={isMobile}>
                {countdown >= 0 && countdown}
            </Number>
        </Container>
    );
};
