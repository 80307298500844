import styled from '@emotion/styled';
import { CSSProperties, MouseEventHandler } from 'react';

import { ReactComponent as DiamondSVG } from '../../assets/cards/diamond.svg';
import { ReactComponent as HeartSVG } from '../../assets/cards/heart.svg';
import { ReactComponent as SpadeSVG } from '../../assets/cards/spade.svg';
import { ReactComponent as ClubSVG } from '../../assets/cards/club.svg';

export interface CardProps {
    face: 'heart' | 'club' | 'diamond' | 'spade';
    value: number;
    flipped: boolean;

    style?: CSSProperties;

    onClick?: () => void;

    isMobile?: boolean;
}

const StyledCard = styled.div<{ isMobile?: boolean }>`
    display: flex;
    flex-direction: column;
    position: absolute;

    flex-wrap: wrap;

    color: black;

    font-size: ${({ isMobile }) => (isMobile ? '2.5rem' : '4rem')};

    height: ${({ isMobile }) => (isMobile ? '10.4rem' : '15.9rem')};
    width: ${({ isMobile }) => (isMobile ? '7.5rem' : '11.4rem')};

    padding: 0em 0.2em;

    box-sizing: border-box;

    background: ${({ isMobile }) =>
        isMobile &&
        `linear-gradient(
        154deg,
        rgba(0, 0, 0, 0.2) 50.29%,
        rgba(0, 0, 0, 0.78) 77.31%,
        rgba(0, 0, 0, 0.9) 88.89%
    );`};

    background-color: white;

    border-radius: 0.2em;

    box-shadow: 0rem 0rem 0.4em 0.2em rgba(0, 0, 0, 0.1);

    font-family: 'Chakra Petch';

    .face {
        svg {
            height: ${({ isMobile }) => (isMobile ? '2rem' : '2.8rem')};
        }
    }

    &.heart,
    &.diamond {
        color: red;
    }

    &.back {
        background-color: darkred;
        border: 0.1em solid white;
    }

    .value {
        font-weight: bold;

        &.value-10 {
            font-size: 0.8em;
        }
    }

    .face-value {
        display: flex;
        flex-direction: row;

        flex: 1;

        justify-content: space-between;

        &.upside-down {
            transform: rotateZ(180deg);
        }
    }
`;

export const getFaceIcon = (face: CardProps['face']) => {
    switch (face) {
        case 'club':
            return <ClubSVG />;
        case 'spade':
            return <SpadeSVG />;
        case 'heart':
            return <HeartSVG />;
        case 'diamond':
            return <DiamondSVG />;
    }
};

export const getValue = (value: number) => {
    switch (value) {
        case 1:
            return 'A';
        case 11:
            return 'J';
        case 12:
            return 'Q';
        case 13:
            return 'K';
        default:
            return Math.max(2, Math.min(value, 10));
    }
};

export const Card = (props: CardProps) => {
    const { flipped, face, value, style, onClick, isMobile } = props;

    const handleClick: MouseEventHandler = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        if (onClick && !flipped) {
            onClick();
        }
    };

    if (!flipped) {
        return (
            <StyledCard
                className="card back"
                style={style}
                onClick={handleClick}
            />
        );
    }
    return (
        <StyledCard
            className={`card ${face}`}
            style={style}
            onClick={handleClick}
            isMobile={isMobile}
        >
            <div className="face-value">
                <div className={`value-${getValue(value)} value`}>
                    {getValue(value)}
                </div>
                <div className={`face ${face}`}>{getFaceIcon(face)}</div>
            </div>
            <div className="face-value upside-down">
                <div className={`value-${getValue(value)} value`}>
                    {getValue(value)}
                </div>
                <div className={`face ${face}`}>{getFaceIcon(face)}</div>
            </div>
        </StyledCard>
    );
};
