import {
    type GameLobbyGameData,
    GameLobbyGameState,
    GameLobbyGameTypes,
} from '@game-lobby/models/types';

import skyCaveBlackJack from './skycave-blackjack.png';
import courtyardPlazaRoulette from './courtyard-plaza-roulette.png';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AnimoUserData } from '@shared/aggregator';
dayjs.extend(utc);
dayjs.extend(timezone);

export const mockLobbyCountDownTimer = dayjs()
    .tz('Australia/Melbourne')
    .add(1, 'hour')
    .add(3, 'second')
    .toISOString();

export const mockUser: AnimoUserData = {
    id: '123456',
    sessionId: 'abc123',
    username: 'elonmusk56',
    name: 'Elon Musk',
    balance: 2000.88,
    currency: 'USD',
    source: 'animo-mock',
};

//const url = new URLSearchParams(window.location.search);

export const mockLobbyGamesData: GameLobbyGameData[] = [
    {
        name: 'SkyCave BlackJack',
        gameServerId: 'id-1',
        type: GameLobbyGameTypes.BLACKJACK,
        numPlayers: 7,
        minBet: 1000,
        maxBet: null,
        thumbnailUrl: skyCaveBlackJack,
        gameState: GameLobbyGameState.PRIVATE,
        requestAccessUrl: '/#sky-cave-black-jack/request-access',
        playGameUrl: `http://localhost:4201?session=${localStorage.getItem(
            'game-lobby_demo_casino_session'
        )}`,
        currency: 'USD',
    },
    {
        name: 'Courtyard Plaza Roulette',
        gameServerId: 'id-2',
        type: GameLobbyGameTypes.ROULETTE,
        numPlayers: 800,
        minBet: 1,
        maxBet: 10000,
        thumbnailUrl: courtyardPlazaRoulette,
        gameState: GameLobbyGameState.DEFAULT,
        requestAccessUrl: '/#court-yard-plaza-roulette/request-access',
        playGameUrl: `http://localhost:4202?session=${localStorage.getItem(
            'game-lobby_demo_casino_session'
        )}`,
        currency: 'USD',
        //playGameUrl: `http://localhost:4201?session=${url.get('session')}`,
    },
    // {
    //     name: 'Scalable Blackjack',
    //     gameServerId: 'id-1',
    //     type: GameLobbyGameTypes.BJ_SCALABLE,
    //     numPlayers: 1234,
    //     minBet: 0.5,
    //     maxBet: null,
    //     thumbnailUrl:
    //         'https://cdn.zone.msn.com/images/v9/en-us/game/bljk/640x345_bljk.png',
    //     gameState: GameLobbyGameState.DEFAULT,
    //     requestAccessUrl: '/#black-jack/request-access',
    //     playGameUrl: 'https://games.gamepix.com/play/40383?sid=10009',
    // },
    // {
    //     name: 'Premium Blackjack',
    //     gameServerId: 'id-2',
    //     type: GameLobbyGameTypes.BJ_PREMIUM,
    //     numPlayers: 2,
    //     minBet: 9999998,
    //     maxBet: 9999999,
    //     thumbnailUrl:
    //         'https://www.livebet.com/images/casino/table-games/platipus/blackjack-vip.webp',
    //     gameState: GameLobbyGameState.PRIVATE,
    //     requestAccessUrl: '/#black-jack/request-access',
    //     playGameUrl:
    //         'https://foony.com/games/8-ball-pool-online-billiards?&platform=crazygames',
    // },
    // {
    //     name: 'Baccarat',
    //     gameServerId: 'id-3',
    //     type: GameLobbyGameTypes.BACCARAT,
    //     numPlayers: 52,
    //     minBet: 5,
    //     maxBet: 10000,
    //     thumbnailUrl:
    //         'https://www.gamblerspick.com//uploads/monthly_2021_03/649968332_Baccarat1.jpg.2e30bf129bc2641696094bf029e47b71.jpg',
    //     gameState: GameLobbyGameState.DEFAULT,
    //     requestAccessUrl: '/#baccarat/request-access',
    //     playGameUrl:
    //         'https://ragdoll-archers.game-files.crazygames.com/ragdoll-archers/41/index.html',
    // },
    // {
    //     name: 'Roulette',
    //     gameServerId: 'id-4',
    //     type: GameLobbyGameTypes.ROULETTE,
    //     numPlayers: 9876,
    //     minBet: 100,
    //     maxBet: 9999,
    //     thumbnailUrl:
    //         'https://www.animationxpress.com/wp-content/uploads/2022/02/roulette-switch-hero.jpg',
    //     gameState: GameLobbyGameState.MAINTENANCE,
    //     requestAccessUrl: '/#roulette/request-access',
    //     playGameUrl:
    //         'https://ninja-parkour-multiplayer.game-files.crazygames.com/ninja-parkour-multiplayer/7/index.html',
    // },
    // {
    //     name: 'Missing Image',
    //     gameServerId: 'id-4',
    //     type: GameLobbyGameTypes.ROULETTE,
    //     numPlayers: 9876,
    //     minBet: 2.5,
    //     maxBet: 50.25,
    //     thumbnailUrl: '',
    //     gameState: GameLobbyGameState.MAINTENANCE,
    //     requestAccessUrl: '/#roulette/request-access',
    //     playGameUrl:
    //         'https://ninja-parkour-multiplayer.game-files.crazygames.com/ninja-parkour-multiplayer/7/index.html',
    // },
];
