export enum PerfectPairs {
  PerfectPair,
  ColouredPair,
  MixedPair,
  None,
}

export enum TwentyOnePlus {
  SuitedTrips,
  StraightFlush,
  ThreeOfKind,
  Straight,
  Flush,
  None,
}

export interface SideBets {
  id: string;
  seat: number;
  perfectPairs: PerfectPairs;
  twentyOnePlus: TwentyOnePlus;
}
