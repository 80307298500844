import styled from '@emotion/styled';

import {
    useLogicStateContext,
    useLogicActionContext,
} from '@blackjack/ui/contexts';

import { keyframes } from '@emotion/react';

import { MobileSeat } from './mobile-seat';

const anim = keyframes`
  from {
    opacity: 0;
    transform: translateY(20rem) scale(0.1);
  }
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
    width: 100%;
    margin: 0 auto;

    position: absolute;
    top: -0.5rem;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: auto;

    animation: ${anim} 500ms;
`;

interface Props {
    isMobile?: boolean;
}

export const MobileBetPlaceHolder = ({ isMobile }: Props) => {
    const { totalCredits, activeChip, betHistory } = useLogicStateContext();

    const { handlePlayerAction } = useLogicActionContext();

    return (
        <Container>
            <MobileSeat
                activeChip={activeChip}
                totalCredits={totalCredits}
                userBets={betHistory}
                onPlayerAction={handlePlayerAction}
            />
        </Container>
    );
};
