import { PropsWithChildren, StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import {
    ChatContextProvider,
    ChipContextProvider,
    WebSocketProvider,
} from '@shared/ui/contexts';
import { DemoCasino } from '@shared/ui/demo-casino';

// App default styles
import '@shared/assets/styles/style.css';

import { BaseApp } from './base-app';
import { Themes, ThemeWrapper } from './theme-wrapper';

export interface GameLayoutProps extends PropsWithChildren {
    name: string;
    theme?: Themes;
}

const params = new URLSearchParams(window.location.search);

const isRoot = window.top === window.self;

const renderCasino =
    process.env.NODE_ENV !== 'production' && !params.has('session') && isRoot;

export const BaseAppLayout = (props: GameLayoutProps) => {
    const { name, theme, children } = props;

    if (renderCasino) {
        return <DemoCasino app={name} />;
    }

    return (
        <StrictMode>
            <BrowserRouter>
                <WebSocketProvider
                    sessionId={params.get('session') || undefined}
                >
                    <ChatContextProvider>
                        <ChipContextProvider>
                            <ThemeWrapper theme={theme}>
                                <BaseApp>{children}</BaseApp>
                            </ThemeWrapper>
                        </ChipContextProvider>
                    </ChatContextProvider>
                </WebSocketProvider>
            </BrowserRouter>
        </StrictMode>
    );
};
