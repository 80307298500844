import styled from '@emotion/styled';
import { Modal } from './modal';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: auto;

    h2 {
        text-transform: uppercase;
        font-size: 3.2rem;
        font-weight: 500;
        color: #ffffff;
        margin-top: 2rem;
    }

    .win-amount {
        font-size: 2.8rem;
        color: #fff;
    }
`;

export interface WinProps {
    totalWon: number;
    isMobile?: boolean;
}

export const Win = (props: WinProps) => {
    const { totalWon, isMobile } = props;

    return (
        <Modal isMobile={isMobile}>
            <Container>
                <h2>{'You Won'}</h2>
                <span className="win-amount">${totalWon}</span>
            </Container>
        </Modal>
    );
};
