import { AudioController } from '../inputs/audio-controller';

interface AudioSettingsProps {
    muted: boolean;
    audioRef: React.RefObject<HTMLAudioElement> | null;
    setMuted: React.Dispatch<React.SetStateAction<boolean>>;
    volume: number;
    setVolume: React.Dispatch<React.SetStateAction<number>>;
}

export const AudioSettings = ({
    muted,
    audioRef,
    setMuted,
    volume,
    setVolume,
}: AudioSettingsProps) => {
    return (
        <AudioController
            muted={muted}
            audioRef={audioRef}
            setMuted={setMuted}
            volume={volume}
            setVolume={setVolume}
        />
    );
};
