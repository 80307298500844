//==================================================================
// UTILITIES
// TODO - replace these temporary utils with a proper currency formatter or move to @shared
// Also see double up in game-lobby/utils
//==================================================================
export function formatCurrency(amount: number, currency = 'USD'): string {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: amount % 1 === 0 ? 0 : 2,
        maximumFractionDigits: 2,
    }).format(amount);
}

const formatCurrencyForBetRange = (
    value: number | null,
    currency = 'USD'
): string => {
    if (value === null) return '';

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits: value % 1 === 0 ? 0 : 2,
        maximumFractionDigits: value % 1 === 0 ? 0 : 2, // Removes trailing 0's if no cents
    }).format(value);
};

export const formatBetRange = (
    minBet: number,
    maxBet: number | null
): string => {
    const formattedMinBet = formatCurrencyForBetRange(minBet);
    if (maxBet === null) {
        return `${formattedMinBet}+`;
    }
    const formattedMaxBet = formatCurrencyForBetRange(maxBet);
    return `${formattedMinBet} - ${formattedMaxBet}`;
};
