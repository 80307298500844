import styled from '@emotion/styled';
import { ReactNode } from 'react';

const Container = styled.div<{ leftSide: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 9rem;
    height: 5rem;

    margin-right: 1rem;

    background: var(--blackjack-opaque-background);

    border: 0.15rem solid var(--primary-border-color);
    border-radius: 1rem;

    font-family: 'Chakra Petch', sans-serif;
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.6rem;

    span {
        color: var(--primary-game-ui-color);
    }
`;

interface Props {
    leftSide?: boolean;
    children: ReactNode;
}

const TableLobbyButtons = (props: Props) => {
    const { leftSide = true, children } = props;

    return (
        <Container leftSide={leftSide}>
            <span>{children}</span>
        </Container>
    );
};

export { TableLobbyButtons, Props };
