import {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from 'react';

import { log } from '@shared/utils';
import { useWebsocketContext } from '@shared/ui/contexts';

import {
    GameLobbyEvents,
    type GameLobbyGameData,
    type GameLobbyState,
    type UpdatedGamesEvent,
} from '@game-lobby/models';
import type {
    GameLobbyContextState,
    GameLobbyReducer,
    GameProviderProps,
} from './types/lobby-context.type';
import { BaseEvents, type UserEvent } from '@shared/events';

import {
    mockLobbyGamesData,
    mockLobbyCountDownTimer,
    mockUser,
} from '../mocks/mock-lobby-data'; // TODO remove

const GameLobbyContext = createContext<GameLobbyContextState | undefined>(
    undefined
);

const initialState: GameLobbyState = {
    user: mockUser,
    gameLobbyGameData: mockLobbyGamesData,
    hasGames: mockLobbyGamesData?.length > 0,
    totalNumPlayers: mockLobbyGamesData?.reduce<number>(
        (acc, obj) => acc + obj.numPlayers,
        0
    ),
    allowedGameUrls: mockLobbyGamesData?.reduce<string[]>((acc, game) => {
        if (game.playGameUrl) {
            const url = new URL(game.playGameUrl);
            acc.push(url.origin);
        }
        return acc;
    }, []),
    countDownTimer: mockLobbyCountDownTimer,
};

const gameLobbyReducer: GameLobbyReducer = (
    state: GameLobbyState,
    event
): GameLobbyState => {
    switch (event.event) {
        case GameLobbyEvents.UpdatedGames: {
            const eventData = event as UpdatedGamesEvent;
            return {
                ...state,
                gameLobbyGameData: eventData.payload.games,
                hasGames: eventData.payload.games.length > 0,
                totalNumPlayers: eventData.payload.games.reduce(
                    (acc, cur) => (acc += cur.numPlayers),
                    0
                ),
            };
        }
        case BaseEvents.User: {
            const eventData = event as UserEvent;
            return {
                ...state,
                user: eventData.payload.user,
            };
        }
        default:
            return state;
    }
};

export const GameLobbyContextProvider = (props: GameProviderProps) => {
    const { children } = props;
    const { socket } = useWebsocketContext();

    // Manage frontend only state outside the reducer
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [activeGameServerId, setActiveGameServerId] = useState<
        GameLobbyGameData['gameServerId'] | null
    >(null);

    const [gameLobbyState, dispatch] = useReducer(
        gameLobbyReducer,
        initialState
    );

    useEffect(() => {
        socket.on(GameLobbyEvents.UpdatedGames, (gameLobbyGameData) => {
            //log.socket(GameLobbyEvents.UpdatedGames, gameLobbyGameData);
            dispatch(gameLobbyGameData);
        });

        socket.on(BaseEvents.User, (userEvent: UserEvent) => {
            //log.socket(BaseEvents.User, userEvent);
            dispatch(userEvent);
        });

        return () => {
            socket.off(GameLobbyEvents.UpdatedGames);
            socket.off(BaseEvents.User);
        };
    }, [socket]);

    // Memoize only gameLobbyState
    const memoizedGameLobbyState = useMemo(
        () => gameLobbyState,
        [gameLobbyState]
    );

    // The rest of the context value without memoization
    const value = {
        gameLobbyState: memoizedGameLobbyState,
        activeGameServerId,
        setActiveGameServerId,
        isModalOpen,
        setIsModalOpen,
    };

    return (
        <GameLobbyContext.Provider value={value}>
            {children}
        </GameLobbyContext.Provider>
    );
};

export const useGameLobbyContext = (): GameLobbyContextState => {
    const context = useContext(GameLobbyContext);
    if (!context) {
        throw new Error(
            'useGameLobbyContext must be used within a GameLobbyContextProvider AND a WebSocketProvider'
        );
    }
    return context;
};
