import { z } from 'zod';

const publicKeySchema = z
    .string()
    .describe('Public RSA key used to validate and decrpyt auth tokens');
const privateKeySchema = z
    .string()
    .describe('Private RSA key used to create and encrypt auth tokens');

export const portSchema = z.coerce.number();
export const useMockServerSchema = z
    .enum(['true', 'false'])
    .transform((value) => value === 'true')
    .default('false');

export const corsSchema = z.string().or(z.string().array()).optional();

export const prodGameEnvironmentSchema = z.object({
    PORT: portSchema,
    PRIVATE_KEY: privateKeySchema,
    PUBLIC_KEY: publicKeySchema,
    RGS_API_HOST: z.string().url(),
    USE_MOCK_SERVER: useMockServerSchema,
    GAME_UUID: z.string().uuid(),
    NODE_ENV: z.enum(['development', 'production', 'staging', 'testing']),
    COOKIE_DOMAIN: z.string(),
    CORS: corsSchema.default('*'),
});

export const devGameEnvironmentSchema = prodGameEnvironmentSchema.extend({
    PUBLIC_KEY: publicKeySchema.default('animo-studios'),
    PRIVATE_KEY: privateKeySchema.default('animo-studios'),
    USE_MOCK_SERVER: useMockServerSchema.default('true'),
    RGS_API_HOST: z.string().default(''),
    COOKIE_DOMAIN: z.string().default('localhost'),
    CORS: corsSchema,
});
