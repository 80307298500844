import { useEffect } from 'react';

import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { ImUndo2 } from 'react-icons/im';
import { IoRepeat } from 'react-icons/io5';

import {
    useGameContext,
    useLogicStateContext,
    useLogicActionContext,
} from '@blackjack/ui/contexts';
import { BlackJackStates } from '@blackjack/models';
import { ChipRowButton, ChipRow } from '@shared/ui/components/chips';

import { DealCardsButton } from '../buttons/deal-cards-button';
import { Timer } from '../timer';

const anim = keyframes`
  from {
    opacity: 0;
    transform: translateY(20rem) scale(0.1);
  }
  to {
    opacity: 1;
  }
`;

const slide = keyframes`
    from {
        transform: translateY(10rem) scale(0.1);
    }
    to {
        transform: translateY(0);
    }
`;

const Container = styled.div`
    width: 93.5rem;
    height: 18.4rem;
    margin: 0 auto;

    position: relative;
    top: 8rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    pointer-events: auto;

    padding-bottom: 3rem;

    animation: ${anim} 500ms;

    .place-your-bets {
        margin-top: 1.8rem;

        h2 {
            text-transform: uppercase;
            color: #fff;

            text-align: center;
            font-family: 'Chakra Petch';
            font-size: 17px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    .button-interaction-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5rem;
    }

    .button-wrapper {
        margin-top: 1.8rem;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        gap: 3.9rem;
    }
`;

const DealCardsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    animation: ${slide} 500ms;

    margin-top: 2.8rem;
    position: relative;
    top: -1rem;

    .clicky {
        pointer-events: auto;
        cursor: pointer;
        user-select: none;
        background: var(--blackjack-side-button);
    }

    .content {
        padding: 1rem 2rem;
        color: white;
        font-family: Chakra Petch;
        font-size: 1.4rem;
    }
`;

interface Props {
    isMobile?: boolean;
}

export const PlayerBetModal = ({ isMobile }: Props) => {
    const {
        totalCredits,
        activeChip,
        canRepeat,
        userTotalSeats,
        baseBetTotal,
        sideBetTotals,
    } = useLogicStateContext();

    const { setActiveChip, undoBet, repeatLast, doubleBets } =
        useLogicActionContext();

    const { gameState, handlePlayerDealNow, muted } = useGameContext();

    const endTime =
        (gameState.roundInfo?.start_time || 0) +
        (gameState.roundInfo?.duration || 0);

    const betAmounts = gameState.gameInfo?.bet_amounts || [];

    const canDealNow =
        gameState.players.every((p) => p.user_id === gameState?.user?.id) &&
        baseBetTotal > 0;

    const canDouble =
        (gameState.user?.balance || 0) >=
            (baseBetTotal * userTotalSeats + sideBetTotals) * 2 &&
        baseBetTotal * 2 <= (gameState.gameInfo?.max_bet || 0);

    const undoButtonDisabled =
        gameState.currentState.next_state !== BlackJackStates.WaitingForBets ||
        baseBetTotal === 0;

    const repeatButtonDisabled =
        gameState.currentState.next_state !== BlackJackStates.WaitingForBets ||
        !canRepeat;

    useEffect(() => {
        if (!activeChip) {
            setActiveChip(gameState.gameInfo?.min_bet as number);
        }
    }, [activeChip, gameState.gameInfo?.min_bet, setActiveChip]);

    return (
        <Container>
            <Timer endTime={endTime} />
            <div className="button-interaction-row">
                <div className="button-wrapper">
                    <ChipRowButton
                        onClickHandler={undoBet}
                        disabled={undoButtonDisabled}
                        text={!isMobile ? 'UNDO' : ''}
                        svg={isMobile ? <ImUndo2 /> : undefined}
                        muted={true}
                    />
                    <ChipRow
                        disabled={
                            gameState.currentState.next_state !==
                            BlackJackStates.WaitingForBets
                        }
                        minBet={gameState.gameInfo?.min_bet || 0}
                        betAmounts={betAmounts}
                        activeChip={activeChip}
                        totalCredits={totalCredits}
                        setActiveChip={setActiveChip}
                    />
                    {baseBetTotal > 0 ? (
                        <ChipRowButton
                            onClickHandler={doubleBets}
                            disabled={undoButtonDisabled || !canDouble}
                            text={'X2'}
                            muted={true}
                        />
                    ) : (
                        <ChipRowButton
                            onClickHandler={repeatLast}
                            disabled={repeatButtonDisabled}
                            svg={<IoRepeat />}
                            muted={true}
                        />
                    )}
                </div>
            </div>
            <div className="place-your-bets">
                <h2>Place Your Bets</h2>
                {canDealNow && (
                    <DealCardsContainer>
                        <DealCardsButton onClick={handlePlayerDealNow} />
                    </DealCardsContainer>
                )}
            </div>
        </Container>
    );
};
