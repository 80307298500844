export enum PlayerState {
    WIN = 'win',
    LOSS = 'loss',
    PUSH = 'push',
    IDLE = 'idle',
    BETTING = 'betting',
    TURN = 'turn',
    FINISHED = 'finished',
    WAITING = 'waiting',
}

export enum PlayerAction {
    BET = 'BET',
    JOIN = 'JOIN',
    SIT = 'SIT',
    HIT = 'HIT',
    STAND = 'STAND',
    DOUBLE = 'DOUBLE',
    SPLIT = 'SPLIT',
    INSURANCE = 'INSURANCE',
}

export type PlayerScore = {
    min: number;
    max: number;
    text: string;
    blackjack: boolean;
};
