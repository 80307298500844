import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';

import { formatCurrency } from '@shared/utils'; // TODO confirm currenty formatting

import { mockSettingsHistory } from '../../../.storybook/mocks/mock-settings-data'; // TODO get real data

const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const GridHeadingRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    margin-top: 1.4rem;
    margin-bottom: 1rem;
    margin-right: 0.55rem; // hacks to prevent layout shift due to scrollbar
    font-family: 'Chakra Petch';
    font-size: 1.4rem;
    font-weight: 500;
    color: #fff;
`;

const GridRowContainer = styled.div<{
    isOverflowing: boolean;
    maxHeight: number;
}>`
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    // hack to prevent layout shift due to overflowY: auto, see global styles.css for .custom-scrollbar width
    // make sure to update the GridHeading padding-right if you change the scrollbar width
    margin-right: ${({ isOverflowing }) =>
        isOverflowing
            ? '0rem'
            : '0.55rem'}; // should be 0.6rem but needs slight tweak to be perfect hard to do with rem units
    max-height: ${({ maxHeight }) => `${maxHeight}rem`};
    overflow: auto;
`;

const GridRow = styled.div`
    display: grid;
    font-size: 1rem;
    line-height: 1.5rem; // 150%
    height: 1.5rem;
    grid-template-columns: 1fr 2fr 1fr 1fr;
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 500;
    color: #fff;
    box-sizing: border-box;
`;

const GridCell = styled.div<{
    textAlign?: 'left' | 'center' | 'right';
    color?: 'green' | 'red';
}>`
    display: flex;
    align-items: center;
    justify-content: ${({ textAlign }) => {
        switch (textAlign) {
            case 'left':
                return 'flex-start';
            case 'right':
                return 'flex-end';
            case 'center':
            default:
                return 'center';
        }
    }};
    color: ${({ color }) => {
        switch (color) {
            case 'green':
                return 'var(--color-rag-green)';
            case 'red':
                return 'var(--color-rag-red)';
            default:
                return 'var(--color-white-100)';
        }
    }};
`;

const ViewMoreButton = styled.button`
    display: flex;
    background: none;
    border: none;
    padding: 0;
    margin-top: 1.4rem;
    color: var(--color-medium-grey);
    font-family: 'Chakra Petch';
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
`;

export const HistorySettings = () => {
    const headings = ['Date', 'Game', 'Bet', 'Profit'];

    const itemsPerPage = 5;
    const [data, setData] = useState(
        mockSettingsHistory.slice(0, itemsPerPage)
    );
    const [currentIndex, setCurrentIndex] = useState(itemsPerPage);

    const gridRowContainerRef = useRef<HTMLDivElement>(null);
    const rowHeight = 1.5;
    const gapHeight = 0.4;
    const maxRowsInView = 10;
    const maxHeight = maxRowsInView * (rowHeight + gapHeight) - gapHeight; // last row has no gap
    const [isOverflowing, setIsOverflowing] = useState(false);

    const handleShowMore = () => {
        if (currentIndex * (rowHeight + gapHeight) - gapHeight >= maxHeight) {
            setIsOverflowing(true);
        }

        const nextItems = mockSettingsHistory.slice(
            currentIndex,
            currentIndex + itemsPerPage
        );
        setData((prevItems) => [...prevItems, ...nextItems]);
        setCurrentIndex(currentIndex + itemsPerPage);
    };

    // TODO - DESIGN TEAM - check if this is desirable
    useEffect(() => {
        if (gridRowContainerRef.current) {
            gridRowContainerRef.current.scrollTo({
                top: gridRowContainerRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [data]);

    return (
        <GridContainer>
            {/* Heading */}
            <GridHeadingRow>
                {headings.map((item, index) => (
                    <GridCell
                        key={item + '-' + index}
                        textAlign={item === 'Date' ? 'left' : 'center'} // TODO - DESIGN TEAM - check this alignment discrepency
                    >
                        {item}
                    </GridCell>
                ))}
            </GridHeadingRow>

            {/* Rows */}
            <GridRowContainer
                ref={gridRowContainerRef}
                maxHeight={maxHeight}
                isOverflowing={isOverflowing}
                className="custom-scrollbar"
            >
                {data.map((item, index) => (
                    <GridRow key={item + '-' + index}>
                        <GridCell textAlign="left">
                            {dayjs(item.date).format('DD:MM:YY')}
                        </GridCell>
                        <GridCell textAlign="left">{item.game}</GridCell>
                        <GridCell>{formatCurrency(item.bet)}</GridCell>
                        <GridCell
                            color={
                                item.profit > 0
                                    ? 'green'
                                    : item.profit < 0
                                    ? 'red'
                                    : undefined
                            }
                        >
                            {formatCurrency(item.profit)}
                        </GridCell>
                    </GridRow>
                ))}
            </GridRowContainer>

            {currentIndex < mockSettingsHistory.length ? (
                <ViewMoreButton onClick={handleShowMore}>
                    VIEW MORE
                </ViewMoreButton>
            ) : (
                <ViewMoreButton>NO MORE RESULTS</ViewMoreButton>
            )}
        </GridContainer>
    );
};
