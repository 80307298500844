import styled from '@emotion/styled';
import { useGameContext, useLogicStateContext } from '@blackjack/ui/contexts';

import ambienceSound from '@shared/assets/audio/ambience-2.mp3';
import { getTotalSideBets } from '@blackjack/utils';

const Container = styled.div`
    height: 5rem;
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    color: #fff;
    margin-top: 0.5rem;

    .box {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;

        flex: 1;
        max-width: 13rem;
        height: 3.7rem;
        margin: 0 0.5rem;
        padding: 0.2rem;

        background: var(--blackjack-opaque-background);

        border: 0.1rem solid var(--primary-border-color);
        border-radius: 0.8rem;

        text-transform: uppercase;
        font-family: 'Chakra Petch', sans-serif;
        font-size: 1.6rem;
        font-weight: 600;
        line-height: normal;

        .title {
            font-size: 0.8rem;
            font-weight: 400;
            line-height: 1.5rem;
            margin-left: 1rem;

            color: var(--secondary-game-ui-color);
        }

        .value,
        .label {
            font-size: 1.6rem;
            font-weight: 600;
            font-style: normal;
            line-height: normal;
            margin-left: 1rem;

            color: var(--primary-game-ui-color);
            white-space: normal; /* Allow text to wrap */
        }

        .table,
        .lobby {
            align-self: center;
            text-align: center;

            font-size: 1.6rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            color: var(--primary-game-ui-color);
        }
    }
`;

export const PlayerInfo = () => {
    const { muted, previousWin, gameState } = useGameContext();

    const { baseBetTotal, audioRef, userTotalSeats, totalCredits } =
        useLogicStateContext();

    const hasPreviousWin = previousWin > 0;

    const totalSideBets = getTotalSideBets(gameState);

    const betAmount = baseBetTotal * userTotalSeats + totalSideBets;

    return (
        <Container>
            <div className="box">
                <span className="title">
                    {hasPreviousWin ? 'LAST WIN' : 'TOTAL BET '}{' '}
                </span>
                <span className="value">
                    $
                    {hasPreviousWin
                        ? previousWin.toLocaleString()
                        : betAmount.toLocaleString()}
                </span>
            </div>
            <div className="box">
                <span className="title">Balance </span>
                <span className="value">${totalCredits.toLocaleString()}</span>
            </div>
            <div className="box">
                <span className="table">+Table</span>
            </div>
            <div className="box">
                <span className="lobby">+LOBBY</span>
            </div>
            <audio autoPlay muted={muted} ref={audioRef} loop>
                <source src={ambienceSound} type="audio/mpeg" />
            </audio>
        </Container>
    );
};
