import { useGameContext } from '@blackjack/ui/contexts';

import styled from '@emotion/styled';
import { useCallback, useMemo } from 'react';
import { Hand } from './hand';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 2.4rem;

    .bottom-right-button-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 5%;
    }
`;

const MobileContainer = styled.div`
    position: absolute;
    bottom: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Hands = styled.div<{ isMobile?: boolean }>`
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: ${({ isMobile }) => isMobile && 'row'};
    justify-content: ${({ isMobile }) => isMobile && 'space-around'};

    margin-top: ${({ isMobile }) => (isMobile ? '2rem' : '2.8rem')};

    .dealer-cards {
        width: 40%;
        display: flex;
        justify-content: flex-end;
        padding-right: 1rem;
    }

    .player-cards {
        width: 40%;
        display: flex;
        justify-content: flex-start;
        padding-left: 1rem;
    }
`;

interface HandsComponentProps {
    isMobile?: boolean;
}

export const HandsComponent = ({ isMobile }: HandsComponentProps) => {
    const { gameState, isOwnSeat } = useGameContext();

    const activeSeat = gameState.currentAction?.seat;

    const isPlayersHand = useCallback(() => {
        if (gameState.currentAction && activeSeat !== undefined) {
            return isOwnSeat(activeSeat);
        }
        return false;
    }, [activeSeat, gameState.currentAction, isOwnSeat]);

    const player = useMemo(() => {
        return gameState.players.find((p) => p.seat === activeSeat);
    }, [gameState.players, activeSeat]);

    const currentHand = gameState.currentState.hand;

    const playerCards = useMemo(() => {
        if (isPlayersHand()) {
            const player = gameState.players.find((p) => p.seat === activeSeat);
            return player?.cards[currentHand] || [];
        }
        return [];
    }, [gameState.players, currentHand, isPlayersHand, activeSeat]);

    const dealerCards = gameState?.dealer?.cards[0] ?? [];

    return isMobile ? (
        <MobileContainer>
            <Hands isMobile>
                <div className="dealer-cards">
                    <Hand
                        isDealer
                        hand={dealerCards}
                        score={gameState.dealer?.scores[0]}
                        isMobile
                        cardScore
                    />
                </div>
                <div className="player-cards">
                    <Hand
                        isDealer={false}
                        hand={playerCards}
                        score={player?.scores[currentHand]}
                        isMobile
                        cardScore
                    />
                </div>
            </Hands>
        </MobileContainer>
    ) : (
        <Container>
            <Hands>
                <Hand
                    isDealer
                    hand={dealerCards}
                    score={gameState.dealer?.scores[0]}
                />
                <Hand
                    isDealer={false}
                    hand={playerCards}
                    score={player?.scores[currentHand]}
                />
            </Hands>
        </Container>
    );
};
