import { useCallback, useMemo, useState } from 'react';
import { PlayerBet, UserBet } from '@blackjack/models';

const uuid = () => {
    const letters = 'abcdefghijklmnopqrstuvwxyz0123456789-';
    const word = [];
    for (let i = 0; i < 30; i += 1) {
        word.push(letters[Math.floor(Math.random() * letters.length)]);
    }

    return word.join('');
};

interface BetHook {
    increaseBet: (seatId: number, bet: number, betType: PlayerBet) => void;
    undoBet: () => void;
    clearBetHistory: () => void;
    totalForType: (betType: PlayerBet, seatId: number) => number;
    storeLastBets: (clearHistory: boolean) => void;
    repeatLast: () => void;
    doubleBets: () => void;
    baseBetTotal: number;
    sideBetTotals: number;
    betHistory: UserBet[];
    lastBet: UserBet | null;
    lastBetTotal: number;
    lastBets: UserBet[];
}

const reduceBets = (bets: UserBet[]) => {
    return bets.reduce((acc: UserBet[], cur) => {
        const previous = acc.find(
            (p) => p.seat === cur.seat && p.type === cur.type
        );

        if (!previous) {
            acc.push({ ...cur, uuid: uuid() });
        } else {
            previous.amount += cur.amount;
        }

        return acc;
    }, []);
};

export const useBetHook = (): BetHook => {
    const [betHistory, setBetHistory] = useState<UserBet[]>([]);
    const [lastBets, setLastBets] = useState<UserBet[]>([]);

    const increaseBet = (seatId: number, bet: number, betType: PlayerBet) => {
        setBetHistory((p) => {
            return [
                ...p,
                {
                    seat: seatId,
                    amount: bet,
                    type: betType || PlayerBet.BASE,
                    uuid: uuid(),
                    doubleId: undefined,
                },
            ];
        });
    };

    const totalForType = useCallback(
        (betType: PlayerBet, seatId: number) => {
            return betHistory.reduce((acc, cur) => {
                if (cur.type === betType && cur.seat === seatId)
                    return acc + cur.amount;
                return acc;
            }, 0);
        },
        [betHistory]
    );

    const undoBet = () => {
        setBetHistory((p) => {
            if (p.length === 0) return [];
            const last = p[p.length - 1];

            if (last.doubleId) {
                return p.filter((b) => b.doubleId !== last.doubleId);
            }
            return [...p.slice(0, p.length - 1)];
        });
    };

    const clearBetHistory = useCallback(() => {
        setBetHistory([]);
    }, []);

    const baseBetTotal = useMemo(() => {
        if (betHistory.length === 0) return 0;
        return betHistory.reduce((acc, cur) => {
            if (cur.type === PlayerBet.BASE) {
                return acc + cur.amount;
            }
            return acc;
        }, 0);
    }, [betHistory]);

    const sideBetTotals = useMemo(
        () =>
            betHistory.reduce((acc, bet) => {
                if (bet.type === PlayerBet.BASE) return acc;
                return acc + bet.amount;
            }, 0),
        [betHistory]
    );

    const lastBet = useMemo(() => {
        if (betHistory.length === 0) return null;
        return betHistory[betHistory.length - 1];
    }, [betHistory]);

    const lastBetTotal = useMemo(() => {
        if (lastBet === null) return 0;
        const total = betHistory.reduce((acc, cur) => {
            if (lastBet.type !== cur.type) return acc;
            if (lastBet.seat === cur.seat || lastBet.type === PlayerBet.BASE)
                return acc + cur.amount;
            return acc;
        }, 0);
        return total;
    }, [betHistory, lastBet]);

    const storeLastBets = useCallback((clearHistory: boolean) => {
        setBetHistory((prev) => {
            const history = reduceBets(prev);
            setLastBets([...history]);
            return clearHistory ? [] : prev;
        });
    }, []);

    const repeatLast = () => {
        const doubleId = uuid();
        setBetHistory(lastBets.map((b) => ({ ...b, doubleId })));
    };

    const doubleBets = () => {
        setBetHistory((prev) => {
            const doubleId = uuid();
            const reduced = reduceBets(prev).map((m) => ({ ...m, doubleId }));
            return [...prev, ...reduced];
        });
    };

    return {
        increaseBet,
        clearBetHistory,
        undoBet,
        totalForType,
        baseBetTotal,
        sideBetTotals,
        betHistory,
        lastBet,
        lastBetTotal,
        lastBets,
        storeLastBets,
        repeatLast,
        doubleBets,
    };
};
