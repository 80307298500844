import { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';

import {
    PlayerAction,
    PlayerBet,
    PlayerData,
    UserBet,
} from '@blackjack/models';
import { getUserBets } from '@blackjack/utils';
import { useGameContext } from '@blackjack/ui/contexts';

import { Chip } from '@shared/ui/components/chips';

import { ReactComponent as CoinPlacement } from '../../assets/icons/coin-placement.svg';
import { ReactComponent as PerfectPairs } from '../../assets/icons/PerfectPair.svg';
import { ReactComponent as TwentyOnePlusThree } from '../../assets/icons/twentyoneplusthree.svg';

const Container = styled.div<{ baseBet: number }>`
    width: 28.4rem;
    height: 16.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: #ffffff;
    font-family: 'Chakra Petch', sans-serif;
    box-sizing: border-box;

    svg .hover {
        opacity: 0;
    }

    .bets {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 16.6rem;

        .bet-section {
            width: 40%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;

            .chip {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: 1;
            }

            .text {
                margin-top: 0.5rem;
                font-size: 1.4rem;
                color: #93c2e8;
                text-align: center;
            }
        }

        .base-bet {
            transform: scale(1.2);
            svg {
                height: 13.8rem;
                width: 18.5rem;
            }
            .chip {
                position: absolute;
                left: 4.9rem;
                height: 9.1rem;
                width: 8rem;
            }
        }

        .perfect-pairs {
            position: relative;
            right: 1.9rem;
            z-index: 1;

            svg {
                height: 14rem;
                width: 7.5rem;
            }

            .chip {
                height: 6rem;
                width: 6rem;
            }
        }

        .twentyone-plus-three {
            position: absolute;
            left: 1rem;

            svg {
                height: 14rem;
                width: 7.5rem;
            }

            .chip {
                height: 6rem;
                width: 6rem;
            }
        }
    }
`;

interface MobileSeatProps {
    userBets: UserBet[];
    activeChip: number | null;
    totalCredits: number;
    onPlayerAction: (
        seatId: number,
        action: PlayerAction,
        section?: PlayerBet,
        isMobile?: boolean
    ) => void;
}

export const MobileSeat = (props: MobileSeatProps) => {
    const { userBets, onPlayerAction } = props;

    const { gameState } = useGameContext();

    const seats = new Array(7).fill({ seat: 0 }).map((_, idx) => {
        return { seat: idx };
    });

    const seatData = useMemo(() => {
        return seats.map((seat) => {
            const player = gameState.players.find(
                (p: PlayerData) => p.seat === seat.seat
            );
            const ownSeat = player?.user_id === gameState.user?.id;

            const bets = getUserBets(player, userBets, ownSeat, seat.seat);

            return { ...seat, player, ownSeat, bets };
        });
    }, [gameState, seats, userBets]);

    const playerBox = seatData.find((box) => box.ownSeat);
    const seated = !!playerBox?.player;

    const handleBet = useCallback(
        (seat: number) => {
            return (action: PlayerAction, section: PlayerBet) => {
                onPlayerAction(seat, action, section, true);
            };
        },
        [onPlayerAction]
    );

    const onBetClick = playerBox && handleBet(playerBox?.seat);

    const handleOnClick = useCallback(
        (type: PlayerAction, section: PlayerBet) => {
            return () => {
                if (onBetClick) {
                    onBetClick(type, section);
                }
            };
        },
        [onBetClick]
    );

    const baseBet =
        playerBox && playerBox.player ? playerBox.player.bets.base : 0;
    const perfectPairsBet =
        playerBox && playerBox.player
            ? playerBox?.player?.bets.perfect_pairs
            : 0;

    const twentyOneBet =
        playerBox && playerBox.player ? playerBox.player.bets.twenty_one : 0;

    const isDisabled = baseBet === 0;

    return (
        <Container baseBet={baseBet}>
            <div className="bets">
                {/* Perfect Pairs */}
                <div className="bet-section ">
                    <div
                        className="perfect-pairs"
                        onClick={
                            isDisabled
                                ? undefined
                                : handleOnClick(
                                      PlayerAction.BET,
                                      PlayerBet.PERFECT_PAIRS
                                  )
                        }
                    >
                        <PerfectPairs />
                        {perfectPairsBet > 0 && (
                            <div className="chip">
                                <Chip value={perfectPairsBet.toString()} />
                            </div>
                        )}
                    </div>
                </div>

                {/* Base Bet */}
                <div className="bet-section base-bet">
                    <div
                        onClick={handleOnClick(
                            seated ? PlayerAction.BET : PlayerAction.SIT,
                            PlayerBet.BASE
                        )}
                    >
                        <CoinPlacement />
                        {baseBet > 0 && (
                            <div className="chip">
                                <Chip size="large" value={baseBet.toString()} />
                            </div>
                        )}
                    </div>
                </div>

                {/* Twenty-One Plus Three */}
                <div className="bet-section ">
                    <div
                        className="twentyone-plus-three"
                        onClick={
                            isDisabled
                                ? undefined
                                : handleOnClick(
                                      PlayerAction.BET,
                                      PlayerBet.TWENTY_ONE
                                  )
                        }
                    >
                        <TwentyOnePlusThree />
                        {twentyOneBet > 0 && (
                            <div className="chip">
                                <Chip value={twentyOneBet.toString()} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Container>
    );
};
