import { useEffect, useRef, useState, ReactNode } from 'react';
import styled from '@emotion/styled';

import { InputRange } from './input-range';

import { ReactComponent as Unmute1Icon } from '../../assets/icons/Unmute_1_icon.svg';
import { ReactComponent as Unmute2Icon } from '../../assets/icons/Unmute_2_icon.svg';
import { ReactComponent as Unmute3Icon } from '../../assets/icons/Unmute_3_icon.svg';
import { ReactComponent as MuteIcon } from '../../assets/icons/Mute_icon.svg';

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    color: var(--color-primary);
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 3rem;
    cursor: pointer;
`;

interface AudioControllerProps {
    muted: boolean;
    audioRef: React.RefObject<HTMLAudioElement> | null;
    setMuted: React.Dispatch<React.SetStateAction<boolean>>;
    volume: number;
    setVolume: (value: number) => void;
}

export const AudioController = ({
    muted,
    audioRef: externalAudioRef,
    setMuted,
    volume,
    setVolume,
}: AudioControllerProps) => {
    const isInitialLoad = useRef(true);
    const internalAudioRef = useRef<HTMLAudioElement>(null); // Internal ref for fallback
    const audioRef = externalAudioRef || internalAudioRef; // Use external ref if provided

    // Sync internal muted state with external muted prop
    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.muted = muted;
        }
    }, [muted, audioRef]);

    const handleVolumeChange = (value: number) => {
        setVolume(value);
        if (audioRef.current) {
            audioRef.current.volume = value / 100;

            // Unmute if volume > 0
            if (value > 0 && muted) {
                setMuted(false);
            }

            // Mute if volume is 0
            if (value === 0 && !muted) {
                setMuted(true);
            }
        }
    };

    const toggleMute = () => {
        if (audioRef.current) {
            const newMutedState = !muted;
            audioRef.current.muted = newMutedState;
            setMuted(newMutedState);
        }
    };

    useEffect(() => {
        // Skip initial load
        if (isInitialLoad.current) {
            isInitialLoad.current = false;
            return;
        }

        // Set initial volume on mount
        if (audioRef.current) {
            audioRef.current.volume = volume / 100;
        }
    }, [audioRef, volume]); // Only run this once on initial mount

    const renderVolumeIcon = (): ReactNode => {
        let VolumeIconComponent;
        if (volume > 66) {
            VolumeIconComponent = Unmute3Icon;
        } else if (volume > 33) {
            VolumeIconComponent = Unmute2Icon;
        } else {
            VolumeIconComponent = Unmute1Icon;
        }

        return (
            <VolumeIconComponent
                onClick={toggleMute}
                height="2.5rem"
                width="3rem"
            />
        );
    };

    return (
        <Container>
            <IconWrapper>
                {muted ? (
                    <MuteIcon
                        height="2.5rem"
                        width="3rem"
                        style={{ transform: 'translate(-0.4rem, 0.1rem)' }}
                        onClick={toggleMute}
                    />
                ) : (
                    renderVolumeIcon()
                )}
            </IconWrapper>

            <InputRange
                value={volume}
                minValue={0}
                maxValue={100}
                onChange={handleVolumeChange}
            />
        </Container>
    );
};
