import styled from '@emotion/styled';
import { mockSettingsInfo } from '../../../.storybook/mocks/mock-settings-data';
import { Accordion } from '../accordion';

const Container = styled.div`
    height: 85%;
    overflow-y: scroll;
`;

export const InfoSettings = () => {
    const data = mockSettingsInfo; // TODO get this for each game?

    return (
        <Container className="custom-scrollbar">
            {data.map((item, index) => (
                <Accordion key={index} title={item.title}>
                    {item.content}
                </Accordion>
            ))}
        </Container>
    );
};
