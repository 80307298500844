import { useState } from 'react';
import styled from '@emotion/styled';

import {
    GameInfo,
    HandsComponent,
    MobileBetPlaceHolder,
} from '@blackjack/ui/components';
import { PlayerInfo } from '../components/mobile/player-info';
import { VirtualTableComponent } from '../components/mobile/virtual-table';
import { useGameContext, useLogicStateContext } from '@blackjack/ui/contexts';

import {
    Chat,
    FullscreenLoader,
    SettingsButtonsRow,
    Timer,
} from '@shared/ui/components';

import { keyframes } from '@emotion/react';
import { TableName } from '@shared/ui/components';

import { CustomPopUp } from '../components/custom-pop-up';
import { useGameMessageHook } from '@blackjack/ui/hooks';
import { useWebsocketContext } from '@shared/ui/contexts';
import { useProgress } from '@react-three/drei';
import { BlackJackStates } from '@blackjack/models';

import blackjackMobileImage from '@shared/assets/images/UI_DEMO_BLACKJACK_MOBILE.png';
import { ReactComponent as MobileMenu } from '../../assets/icons/mobile-menu.svg';
import { ReactComponent as MobileClose } from '../../assets/icons/mobile-close.svg';

import ambienceSound from '@shared/assets/audio/ambience-2.mp3';

const slideMenusIn = keyframes`
  from {
    transform:  rotate(90deg);
        opacity: 0;

  }
  to {
    transform: rotate(90deg);
    opacity:1;
  }
`;

const slideMenusOut = keyframes`
  from {
    transform:  rotate(90deg);
    opacity: 1;
  }
  to {
    transform:  rotate(90deg);
    opacity: 0;
  }
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    background-image: url(${blackjackMobileImage});
    background-size: cover;
    background-position: center;
    object-fit: contain;

    font-family: 'Chakra Petch', sans-serif;
`;

const MobileTop = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    z-index: 0;
`;

const MobileMidSection = styled.div`
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;

    .chip-row {
        height: 11rem;
        width: 35.3rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
`;

const MobileFooter = styled.div`
    width: 100%;
    height: 13%;
`;

const HeadingContainer = styled.div`
    width: 98%;
    position: relative;
    top: 2.2rem;

    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

    .heading-top-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .heading-second-row {
        margin-top: 1.4rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .chat {
            position: relative;
            left: -1.8rem;
        }
    }

    .game-info {
        height: 5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .menus {
        height: 4rem;
        width: 4rem;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 0.8rem;

        background: var(--blackjack-opaque-background);
        border: 0.1rem solid var(--primary-border-color);

        border-radius: 1rem;

        box-sizing: border-box;
    }

    .timer {
        height: 4rem;
        width: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const MobileBetPlaceholder = styled.div`
    position: relative;
    margin-bottom: 1rem;

    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    flex: 1;
    pointer-events: none;
`;

const SettingsContainer = styled.div<{ showMenus?: boolean }>`
    display: flex;
    position: absolute;
    top: 18rem;

    animation: ${({ showMenus }) => (showMenus ? slideMenusIn : slideMenusOut)}
        0.5s forwards;

    svg {
        transform: rotate(-90deg);
    }
`;

export const MobileAppLayout = () => {
    const { gameState, muted, toggleMute } = useGameContext();
    const { audioRef, isReady } = useLogicStateContext();

    const { active, progress } = useProgress();

    const { duplicateSession } = useWebsocketContext();

    const [finishedLoading, setFinishedLoading] = useState(false);

    const [showSettings, setShowSettings] = useState(false);

    const [showMenus, setShowMenus] = useState(false);

    const endTime =
        (gameState.roundInfo?.start_time || 0) +
        (gameState.roundInfo?.duration || 0);

    const gameMessage = useGameMessageHook(gameState, 7, isReady);

    return (
        <Container>
            <MobileTop>
                <HeadingContainer>
                    <div className="heading-top-row">
                        <div className="top-row">
                            {gameState && gameState.gameInfo && (
                                <TableName
                                    gameName={gameState?.gameInfo.name}
                                    minBet={gameState.gameInfo.min_bet}
                                    maxBet={gameState.gameInfo.max_bet}
                                    isMobile={true}
                                />
                            )}
                        </div>
                        <div className="right-heading">
                            <div className="menus">
                                <span onClick={() => setShowMenus(!showMenus)}>
                                    {!showMenus ? (
                                        <MobileMenu />
                                    ) : (
                                        <MobileClose />
                                    )}
                                </span>
                                {showMenus && (
                                    <SettingsContainer showMenus={showMenus}>
                                        <SettingsButtonsRow
                                            muted={muted}
                                            toggleMute={toggleMute}
                                            isMobile
                                        />
                                    </SettingsContainer>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="heading-second-row">
                        <div className="chat">
                            <Chat
                                userId={gameState.user?.id}
                                isMobile
                                showChat
                            />
                        </div>
                        <div className="timer">
                            <span>
                                {gameState.currentState.next_state ===
                                    BlackJackStates.WaitingForBets && (
                                    <Timer endTime={endTime} isMobile={true} />
                                )}
                            </span>
                        </div>
                    </div>
                </HeadingContainer>
                <VirtualTableComponent />
            </MobileTop>
            <MobileMidSection>
                <MobileBetPlaceholder>
                    {gameState.currentState.next_state ===
                        BlackJackStates.WaitingForBets && (
                        <MobileBetPlaceHolder />
                    )}
                    <HandsComponent isMobile={true} />
                </MobileBetPlaceholder>
                <div className="chip-row">
                    <GameInfo isMobile={true} />
                </div>
            </MobileMidSection>
            <MobileFooter>
                <PlayerInfo />
            </MobileFooter>
            <CustomPopUp
                isVisible={
                    gameMessage.show &&
                    !duplicateSession &&
                    finishedLoading &&
                    !showSettings
                }
                onClose={() => null}
                message={gameMessage.message}
                isMobile={true}
            />
            <FullscreenLoader
                progress={((isReady ? 1.0 : 0) + progress / 100.0) / 2}
                minTime={0}
                isLoading={!isReady || active}
                onFinish={() => setFinishedLoading(true)}
            />
            <audio autoPlay muted={muted} ref={audioRef} loop>
                <source src={ambienceSound} type="audio/mpeg" />
            </audio>
        </Container>
    );
};
