import styled from '@emotion/styled';
import clsx from 'clsx';

import { Chip } from './chip';

import Chip1 from '@shared/assets/audio/1chip.mp3';
import Chip5 from '@shared/assets/audio/5chip.mp3';
import Chip10 from '@shared/assets/audio/10chip.mp3';
import Chip25 from '@shared/assets/audio/25chip.mp3';
import Chip100 from '@shared/assets/audio/100chip.mp3';
import Chip500 from '@shared/assets/audio/500chip.mp3';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;

    .chips {
        display: flex;
        gap: 1.1rem;

        .chip {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 7.8rem;
            height: 7.8rem;
            box-sizing: border-box;

            .chip-wrapper {
                box-sizing: border-box;
                border: 0.2rem solid transparent;
                border-radius: 50%;
            }

            &.selected {
                .chip-wrapper {
                    padding: 0.3rem;
                    border-color: var(--selected-chip);
                }

                &.disabled {
                    padding: 0;
                    border-color: transparent;
                }
            }

            svg .hover {
                opacity: 0;
                transform: translate(7.8rem, 7.8rem);
                transition: opacity 250ms, transform 500ms ease-in-out;
            }

            &:hover {
                .hover {
                    transform: translate(-10rem, -10rem);
                    opacity: 1;
                }

                &.disabled {
                    padding: 0;
                    border-color: transparent;
                    svg .hover {
                        opacity: 0;
                    }
                }
            }
        }
    }
`;

export interface ChipSelectorProps {
    betAmounts: number[];
    activeChip: number | null;
    totalCredits: number;
    minBet: number;
    setActiveChip: (amount: number | null) => void;
    isMobile?: boolean;
    disabled?: boolean;
}

// sufficient number of chips sounds to match number of chips
const CHIP_SOUNDS = [
    Chip1,
    Chip5,
    Chip10,
    Chip25,
    Chip25,
    Chip100,
    Chip500,
    Chip500,
];

const isChipDisabled = (
    amount: number,
    minBet: number,
    totalCredits: number,
    disabled?: boolean
) => {
    if (disabled) return true;
    if (amount > totalCredits) return true;
    if (amount < minBet) return true;
    return false;
};

export const ChipRow = (props: ChipSelectorProps) => {
    const {
        betAmounts,
        activeChip,
        minBet,
        totalCredits,
        disabled,
        setActiveChip,
        isMobile,
    } = props;

    const handleClick = (amount: number, idx: number) => {
        return () => {
            if (isChipDisabled(amount, minBet, totalCredits, disabled)) return;

            const sound = new Audio(CHIP_SOUNDS[idx]);
            sound.volume = 0.4;
            sound.play();

            setActiveChip(amount);
        };
    };

    return (
        <Container>
            <div className="chips">
                {betAmounts.map((amount: number, idx: number) => (
                    <div
                        key={`chip-${amount}`}
                        className={clsx('chip', 'chip-selected', {
                            selected: amount === activeChip,
                            disabled: isChipDisabled(
                                amount,
                                minBet,
                                totalCredits,
                                disabled
                            ),
                        })}
                        onClick={handleClick(amount, idx)}
                    >
                        <div className="chip-wrapper">
                            <Chip
                                size={'large'}
                                value={amount}
                                disabled={isChipDisabled(
                                    amount,
                                    minBet,
                                    totalCredits,
                                    disabled
                                )}
                                isMobile={isMobile}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </Container>
    );
};
