import { IUserServer } from '@shared/websocket';
import { IStateMachine } from '@shared/state-machine';

import { GameLobbyStates } from './events.type';
import { AnimoUserData } from '@shared/aggregator';

export enum GameLobbyGameState {
    DEFAULT = 'default',
    PRIVATE = 'private',
    MAINTENANCE = 'maintenance',
}

export enum GameLobbyGameTypes {
    BACCARAT = 'baccarat',
    BLACKJACK = 'blackjack',
    SCALABLE_BLACKJACK = 'scalable-blackjack',
    ROULETTE = 'roulette',
}

export type GameLobbyState = {
    user: AnimoUserData | null;
    gameLobbyGameData: GameLobbyGameData[] | null;
    hasGames: boolean;
    totalNumPlayers: number;
    allowedGameUrls: GameLobbyGameData['playGameUrl'][];
    countDownTimer: string;
};

export type GameLobbySettings = {
    name: string;
    gamesApiHost?: string;
    useGamesApi: boolean;
    redisDB?: string;
};

export type GameDataMap = {
    add: (gameServerId: string, data: GameLobbyGameData) => void;
    update: (gameServerId: string, data: Partial<GameLobbyGameData>) => void;
    delete: (gameServerId: string) => void;
    get: (gameServerId: string) => GameLobbyGameData | undefined;
    toArray: () => GameLobbyGameData[];
    onChange: (changeHandler: (games: GameLobbyGameData[]) => void) => void;
};

export type GameLobbyContext = {
    games: GameDataMap;
    stateMachine: IStateMachine<GameLobbyState, GameLobbyStates>;
    userServer: IUserServer;
    settings: GameLobbySettings;
};

export type GameLobbyGameData = {
    name: string;
    gameServerId: string;
    type: GameLobbyGameTypes;
    numPlayers: number;
    minBet: number;
    maxBet: number | null;
    thumbnailUrl: string;
    gameState: GameLobbyGameState;
    requestAccessUrl: string;
    playGameUrl: string;
    currency: string;
};
