import styled from '@emotion/styled';
import { BalanceBetButtons } from './balance-bet-buttons';
import { useGameContext } from '@blackjack/ui/contexts';
import { Chat, TableName } from '@shared/ui/components';

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 2.4rem;

    .chat-wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
        position: relative;
        margin-top: 0.7rem;
    }

    .bottom-button-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5%;
    }
`;

interface Props {
    betAmount: number;
    credits: number;
    previousWin: number;
}

const LeftColumn = (props: Props) => {
    const { betAmount, credits, previousWin } = props;

    const hasPreviousWin = previousWin > 0;

    const { gameState } = useGameContext();

    return (
        <Container>
            {gameState && gameState.gameInfo && (
                <TableName
                    gameName={gameState?.gameInfo.name}
                    minBet={gameState.gameInfo.min_bet}
                    maxBet={gameState.gameInfo.max_bet}
                />
            )}
            <div className="chat-wrapper">
                <Chat userId={gameState.user?.id} />
            </div>
            <div className="bottom-button-row">
                <BalanceBetButtons
                    title={hasPreviousWin ? 'Last Win' : 'Total Bet'}
                >
                    $
                    {hasPreviousWin
                        ? previousWin.toLocaleString()
                        : betAmount.toLocaleString()}
                </BalanceBetButtons>
                <BalanceBetButtons title="Balance" leftSide={true}>
                    ${credits.toLocaleString()}
                </BalanceBetButtons>
            </div>
        </Container>
    );
};

export { LeftColumn, Props };
