import { IPlayerScore, PlayerAction, PlayerState } from '@blackjack/models';
import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';

import { ReactComponent as BlackJackSVG } from '../../assets/icons/Blackjack.svg';
import { ReactComponent as WinSVG } from '../../assets/icons/Win.svg';
import { ReactComponent as PushSVG } from '../../assets/icons/Push.svg';
import { ReactComponent as BustSVG } from '../../assets/icons/Bust.svg';
import { ReactComponent as DoubleSVG } from '../../assets/icons/Double.svg';
import { ReactComponent as SplitSVG } from '../../assets/icons/Split.svg';
import { ReactComponent as HitSVG } from '../../assets/icons/Hit.svg';
import { ReactComponent as StandSVG } from '../../assets/icons/Stand.svg';

export interface ScoreProps {
    score: IPlayerScore;
    selected?: boolean;
    lastAction?: PlayerAction | null;
    playerState?: PlayerState;
    updated?: number | null; // Last time the action was update
    isMobile?: boolean;
    cardScore?: boolean;
}

const MobileContainer = styled.div<{ cardScore?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: 'Chakra Petch';

    pointer-events: auto;

    color: white;

    height: ${({ cardScore }) => !cardScore && '2.1rem'};
    width: ${({ cardScore }) => !cardScore && '3.1rem'};
    border-radius: ${({ cardScore }) => !cardScore && '0.6rem'};
    background-color: ${({ cardScore }) => !cardScore && 'rgba(0, 0, 0, 0.60)'};
    backdrop-filter: ${({ cardScore }) => !cardScore && 'blur(0.5rem)'};
    border: 0.1rem solid transparent;

    color: white;
    text-align: center;
    font-family: 'Chakra Petch';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    transition: border-color 200ms, background-color 200ms,
        color 200ms ease-in-out;

    margin-bottom: 1rem;

    // &.selected {
    //     stroke: rgba(100, 255, 255, 1);
    //     stroke-width: 0.1rem;
    // }

    .svg-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .score-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 60%;
        transform: translate(-50%, -50%);

        text-align: center;
        color: white;
        font-size: 1.5rem;
        font-weight: bold;
        pointer-events: none;
    }

    &.STAND,
    &.HIT {
        svg {
            min-width: 4rem;
        }
        background-color: transparent;
    }

    &.SPLIT {
        // background-color: rgba(30, 50, 255, 1);
        color: white;
        svg {
            // height: 1.4rem;
        }
    }

    &.DOUBLE {
        background-color: rgba(0, 0, 0, 1);
        color: white;
        svg {
            height: 1.4rem;
        }
    }

    &.blackjack,
    &.bust {
        background-color: black;
        color: white;

        overflow: hidden;

        svg {
            height: 1.8rem;
        }
    }

    &.win,
    &.push,
    &.loss {
        background-color: black;
        color: white;
        svg {
            height: 1.6rem;
            fill: #ffffff;
        }
    }

    .loss-icon {
        font-size: 1.5rem;
        overflow: hidden;
        font-weight: 700;
        color: white;
    }
`;

const Container = styled.div`
    height: 2.1rem;
    width: 3.6rem;

    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: auto;

    border-radius: 0.6rem;
    border: 0.1rem solid transparent;

    backdrop-filter: blur(0.5rem);

    background-color: rgba(0, 0, 0, 0.6);

    color: white;

    font-family: 'Chakra Petch';
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;

    transition: border-color 200ms, background-color 200ms,
        color 200ms ease-in-out;

    margin-bottom: 1rem;

    // &.selected {
    //     // border-color: rgba(100, 255, 255, 1);

    //     stroke: rgba(100, 255, 255, 1);
    //     stroke-width: 0.1rem;
    // }

    .svg-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .score-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 60%;
        transform: translate(-50%, -50%);

        text-align: center;
        color: white;
        font-size: 1.5rem;
        font-weight: bold;
        pointer-events: none;
    }

    &.STAND,
    &.HIT {
        background-color: transparent;
    }

    &.SPLIT {
        background-color: rgba(30, 50, 255, 1);
        color: white;
        svg {
            height: 1.4rem;
        }
    }

    &.DOUBLE {
        background-color: rgba(0, 0, 0, 1);
        color: white;
        svg {
            height: 1.4rem;
        }
    }

    &.blackjack,
    &.bust {
        background-color: black;
        color: white;

        overflow: hidden;

        svg {
            height: 1.8rem;
        }
    }

    &.win,
    &.push,
    &.loss {
        background-color: black;
        color: white;
        svg {
            height: 1.6rem;
            fill: #ffffff;
        }
    }

    .loss-icon {
        font-size: 2rem;
        overflow: hidden;
        font-weight: 700;
        color: white;
    }
`;

export const Score = (props: ScoreProps) => {
    const {
        score,
        selected,
        lastAction,
        playerState,
        updated,
        isMobile,
        cardScore,
    } = props;
    const [showLastAction, setShowLastAction] = useState(false);

    const className = useMemo(() => {
        const cln = selected ? ' selected' : '';
        if (score.blackjack) return `blackjack${cln}`;
        if (score.max > 21) return `bust${cln}`;
        return `${cln} ${lastAction} ${showLastAction ? 'last-action' : ''}`;
    }, [score, selected, lastAction, showLastAction]);

    const value = useMemo(() => {
        if (playerState === PlayerState.WIN) return <WinSVG />;
        if (playerState === PlayerState.PUSH) return <PushSVG />;
        if (playerState === PlayerState.LOSS)
            return <div className="loss-icon">X</div>;

        if (score.blackjack) return <BlackJackSVG />;
        if (score.max > 21) return <BustSVG />;

        const renderActionWithScore = (
            SVGComponent: JSX.Element,
            showScore: boolean
        ) => (
            <div className="svg-container">
                {SVGComponent}
                {showScore && <div className="score-overlay">{score.text}</div>}
            </div>
        );

        if (showLastAction) {
            switch (lastAction) {
                case PlayerAction.HIT:
                    return renderActionWithScore(<HitSVG />, true);
                case PlayerAction.STAND:
                    return renderActionWithScore(<StandSVG />, true);
                case PlayerAction.SPLIT:
                    return <SplitSVG />;
                case PlayerAction.DOUBLE:
                    return <DoubleSVG />;
            }
        }
        return score.text;
    }, [score, lastAction, showLastAction, playerState]);

    useEffect(() => {
        if (lastAction) {
            setShowLastAction(true);
            setTimeout(() => {
                setShowLastAction(true);
            }, 3000);
        }
    }, [lastAction, updated]);

    return isMobile ? (
        <MobileContainer
            cardScore={cardScore}
            className={`score ${className} ${playerState}`}
        >
            {value}
        </MobileContainer>
    ) : (
        <Container className={`score ${className} ${playerState}`}>
            {value}
        </Container>
    );
};
