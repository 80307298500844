import { GameState, IPlayerBets, PlayerBet } from '@blackjack/models';

export const getTotalSideBets = (gameState: GameState) => {
    const userId = gameState.user?.id;

    return gameState.players.reduce((total, player) => {
        if (player.user_id === userId) {
            const bets = player.bets;
            Object.entries(bets).forEach(([b, value]) => {
                const betKey = b as keyof IPlayerBets;
                if (
                    value &&
                    (betKey === PlayerBet.PERFECT_PAIRS ||
                        betKey === PlayerBet.TWENTY_ONE)
                ) {
                    total += value;
                }
            });
        }
        return total;
    }, 0);
};
