import { useState } from 'react';
import clsx from 'clsx';

import { ReactComponent as DealCards } from '../../assets/icons/Deal_Cards.svg';
import { ReactComponent as DealCardsHover } from '../../assets/icons/Deal_Cards_Hover.svg';

import styled from '@emotion/styled';

const Container = styled.div`
    margin-top: 1rem;
    position: relative;
    .box {
        opacity: 1;
        transition: opacity 100ms ease-in-out;
    }
    .box2 {
        opacity: 0;
        transition: opacity 100ms ease-in-out;
        position: absolute;
        top: 0;
    }
`;

export const DealCardsButton = (props: { onClick?: () => void }) => {
    const [isHovered, setIsHovered] = useState(false);

    const { onClick } = props;
    return (
        <Container
            className={clsx(
                'clicky',
                `switchable-svg ${!isHovered ? 'hovered' : ''}`
            )}
            onClick={onClick}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {!isHovered ? <DealCards /> : <DealCardsHover />}
        </Container>
    );
};
