import styled from '@emotion/styled';
import { SettingMenuItems } from './settings.type';
import { ChatSettings } from './settings-modal-content-chat';
import { AudioSettings } from './settings-modal-content-audio';
import { OtherSettings } from './settings-modal-content-other-settings';
import { HistorySettings } from './settings-modal-content-history';
import { InfoSettings } from './settings-modal-content-info';

import { ReactComponent as HistoryIcon } from '../../assets/icons/History.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/Info.svg';

const Container = styled.div<{
    isOpen: boolean;
    isMobile: boolean;
    height: string;
}>`
    position: absolute;
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    top: ${({ isMobile }) => (isMobile ? '50%' : '5.2rem')};
    left: 0;
    right: 0;
    padding: 2.6rem 3rem;
    // min-width: 30rem; relies on the width of setting-buttons-row
    min-height: 5rem;
    height: ${({ height }) => (height ? height : '30rem')};
    max-height: 30rem;
    border-radius: 8px;
    border-top: 2px solid var(--color-secondary);
    background: rgba(18, 18, 18, 0.91);
    transform: ${({ isMobile, height }) =>
        isMobile
            ? height === '4rem'
                ? 'rotate(-90deg) translate(-12.5rem, -15rem)'
                : 'rotate(-90deg) translateY(-2.5rem)'
            : ''};
    overflow: hidden;

    & svg {
        color: var(--color-primary);
        transform: ${({ isMobile }) => (isMobile ? 'rotate(360deg)' : '')};
    }
`;

const Heading = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.8rem;
    font-family: 'Chakra Petch', sans-serif;
    font-weight: 400;
    color: #fff;
    margin-bottom: 1rem;

    & svg {
        color: var(--color-primary);
    }
`;

interface SettingsModalProps {
    activeSetting: SettingMenuItems | null;
    isMobile: boolean;
    muted: boolean;
    audioRef?: React.RefObject<HTMLAudioElement> | null;
    setMuted?: React.Dispatch<React.SetStateAction<boolean>>;
    volume?: number;
    setVolume?: React.Dispatch<React.SetStateAction<number>>;
}

const noop = () => {
    /* no operation */
}; // temp solution to avoid conflict with games that dont use base-layout yet

export const SettingsModal = ({
    activeSetting,
    isMobile,
    muted = false,
    audioRef = null,
    setMuted = noop,
    volume = 1,
    setVolume = noop,
}: SettingsModalProps) => {
    if (!activeSetting) return null;

    let icon;
    let heading;
    let content;
    let shouldRotate = false;
    let height = '30rem';

    switch (activeSetting) {
        case SettingMenuItems.CHAT:
            content = <ChatSettings />;
            break;
        case SettingMenuItems.AUDIO:
            content = (
                <AudioSettings
                    muted={muted}
                    audioRef={audioRef}
                    setMuted={setMuted}
                    volume={volume}
                    setVolume={setVolume}
                />
            );
            shouldRotate = true;
            height = '4rem';
            break;
        case SettingMenuItems.OTHER:
            content = <OtherSettings />;
            break;
        case SettingMenuItems.HISTORY:
            icon = <HistoryIcon width="3.2rem" height="3.2rem" />;
            heading = 'Game History';
            content = <HistorySettings />;
            shouldRotate = true;
            break;
        case SettingMenuItems.INFO:
            icon = <InfoIcon width="3rem" height="3rem" />;
            heading = 'Information';
            content = <InfoSettings />;
            shouldRotate = true;
            break;
        default:
            content = null;
    }

    return (
        <Container
            className="modal"
            isOpen={activeSetting != null}
            isMobile={isMobile && shouldRotate}
            height={height}
        >
            <Heading>
                {icon && icon}
                {heading && heading.toUpperCase()}
            </Heading>
            {content && content}
        </Container>
    );
};
