/**
 * 3D Table UI to display
 * seats and chips
 */

import styled from '@emotion/styled';
import * as THREE from 'three';
import {
    Html,
    OrbitControls,
    PerspectiveCamera,
    Preload,
} from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import { ReactElement, Suspense, useCallback, useMemo } from 'react';
import { Seat, SeatProps } from './seat';
import { degToRad } from 'three/src/math/MathUtils';
import {
    PlayerAction,
    PlayerBet,
    PlayerData,
    PlayerState,
    UserBet,
} from '@blackjack/models';
import { PlayerName, Score } from '@blackjack/ui/components';
import { useGameContext } from '@blackjack/ui/contexts';
import { getUserBets } from '@blackjack/utils';

export interface TableConfig {
    totalSeats: number;
    startAngle: number;
    endAngle: number;
    radius: number;
    center: {
        x: number;
        y: number;
    };
}

export interface CameraConfig {
    position: {
        x: number;
        y: number;
        z: number;
    };
    lookAt: {
        x: number;
        y: number;
        z: number;
    };
    focalLength: number;
    orbitControls: boolean;
    grid: boolean;
}

export interface SeatPlayer {
    playerId: string;
    seat: number;
    ownSeat: boolean;
    bets?: PlayerData['bets'];
    winAmounts?: PlayerData['winAmounts'];
    insurance?: number;
}

export interface VirtualTableProps {
    config?: Partial<TableConfig>;
    cameraConfig?: Partial<CameraConfig>;
    state?: SeatProps['mode'];
    players: PlayerData[];
    currentTurn?: number | null;
    userBets?: UserBet[];
    userId: string;
    muted: boolean;
    onPlayerAction?: (
        seat: number,
        action: PlayerAction,
        section?: PlayerBet,
        isMobile?: boolean
    ) => void;
    onPlayerClick: (seat: number) => void;
    onPlayerPreAction?: (
        action: PlayerAction,
        seatId: number,
        isPreAction?: boolean
    ) => void;
    isMobile?: boolean;
    replicateSideBets: boolean;
}

const defaultTableConfig: TableConfig = {
    totalSeats: 7,
    startAngle: -48,
    endAngle: 48,
    radius: 4.0,
    center: {
        x: -0.05,
        y: -0.7,
    },
};

const defaultCameraConfig: CameraConfig = {
    position: {
        x: -0.0,
        y: 2.630863487908139,
        z: 5.851068591837024,
    },
    lookAt: {
        x: -0.0,
        y: -0.0,
        z: 0.06093042935053737,
    },
    focalLength: 16,
    orbitControls: false,
    grid: false,
};

const mergeConfigs = <T extends object>(
    config: Partial<T> | undefined,
    defaultConfig: T
): T => {
    if (!config) return { ...defaultConfig };
    return { ...defaultConfig, ...config };
};

const PLANE_ROTATION: [number, number, number] = [degToRad(-90), 0, 0];

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
`;

const Scene = ({
    children,
    cameraConfig,
}: {
    children?: ReactElement | ReactElement[];
    cameraConfig: CameraConfig;
}) => {
    //const [config] = useState(cameraConfig);
    const config = cameraConfig;

    useThree(({ camera }) => {
        if (!config.orbitControls) {
            const cam = camera as THREE.PerspectiveCamera;
            cam.position.set(
                config.position.x,
                config.position.y,
                config.position.z
            );

            cam.lookAt(config.lookAt.x, config.lookAt.y, config.lookAt.z);
            cam.setFocalLength(config.focalLength);
        }
    });

    return (
        <group>
            <ambientLight intensity={1.0} />
            <directionalLight
                castShadow
                position={[10, 30, -15]}
                intensity={2.0}
            />
            {config.grid && <gridHelper args={[10, 10]} />}
            {config.orbitControls && <OrbitControls />}
            {children}
            <mesh
                rotation={PLANE_ROTATION}
                position={[0, 0.0, 0]}
                receiveShadow={true}
            >
                <planeGeometry args={[10, 10]} />
                <shadowMaterial
                    transparent={true}
                    depthWrite={false}
                    opacity={0.8}
                />
            </mesh>
        </group>
    );
};

// const Loader = () => {
//   const { progress } = useProgress();
//
//   return (
//     <div
//       style={{
//         width: '40rem',
//         height: '20rem',
//         backgroundColor: 'red',
//         color: 'red',
//         position: 'absolute',
//         zIndex: 10001,
//       }}
//     >
//       Loading {progress} % loaded
//     </div>
//   );
// };
//

export const VirtualTable = (props: VirtualTableProps) => {
    const {
        state = 'LOCKED',
        userBets = [],
        currentTurn = -1,
        onPlayerAction = () => null,
        onPlayerClick = () => null,
        onPlayerPreAction = () => null,
        isMobile,
        replicateSideBets,
    } = props;

    const { gameState, handlePlayerKick } = useGameContext();

    const tableConfig = mergeConfigs(props.config, defaultTableConfig);

    const cameraConfig = mergeConfigs(
        { ...props.cameraConfig },
        defaultCameraConfig
    );

    const isPlayerAtSeatLimit =
        gameState.players.filter(
            (player) => player.user_id === gameState.user?.id
        ).length === gameState.gameInfo?.tableSeatLimit;

    const seats = useMemo<
        {
            seat: number;
            position: [number, number, number];
            rotation: [number, number, number];
        }[]
    >(() => {
        const angleSpace =
            (tableConfig.endAngle - tableConfig.startAngle) /
            tableConfig.totalSeats;
        const radius = tableConfig.radius;
        const center = tableConfig.center;

        const arr = new Array(tableConfig.totalSeats)
            .fill({ seat: 0, position: [0, 0, 0], rotation: [0, 0, 0] })
            .map((s, idx) => {
                const angle = angleSpace * (idx + 0.5) + tableConfig.startAngle;
                const x =
                    radius * Math.sin((Math.PI * 2 * angle) / 360) + center.x;
                const y =
                    radius * Math.cos((Math.PI * 2 * angle) / 360) + center.y;
                return {
                    ...s,
                    seat: tableConfig.totalSeats - 1 - idx,
                    position: [x, 0, y],
                    rotation: [0, degToRad(angle), 0],
                };
            });

        return arr;
    }, [tableConfig]);

    const handleClick = useCallback(
        (seat: number) => {
            return (action: PlayerAction, section: PlayerBet) => {
                onPlayerAction(seat, action, section);
            };
        },
        [onPlayerAction]
    );

    const seatData = useMemo(() => {
        return seats.map((seat) => {
            const player = gameState.players.find(
                (p: PlayerData) => p.seat === seat.seat
            );
            const ownSeat = player?.user_id === props.userId;

            const bets = getUserBets(player, userBets, ownSeat, seat.seat);

            return { ...seat, player, ownSeat, bets };
        });
    }, [gameState, props.userId, seats, userBets]);
    return (
        <Container>
            <Canvas camera={{ near: 1, far: 20 }} shadows flat dpr={2} linear>
                <Suspense fallback={null}>
                    <PerspectiveCamera makeDefault />
                    <Scene cameraConfig={cameraConfig}>
                        <group position={isMobile ? [0, 0, 0] : [0, 0, 0]}>
                            <Html
                                zIndexRange={[5, 0]}
                                position={
                                    isMobile ? [-0.5, -0.5, 0] : [0, -1.5, 0]
                                }
                            >
                                {gameState.dealer &&
                                    gameState.dealer.cards[0].length > 0 && (
                                        <Score
                                            score={gameState.dealer.scores[0]}
                                            selected={false}
                                            lastAction={
                                                gameState.dealer.lastAction
                                            }
                                            updated={
                                                gameState.dealer
                                                    .lastActionTimes[0]
                                            }
                                            playerState={gameState.dealer.state}
                                        />
                                    )}
                            </Html>
                            {seatData.map((box) => {
                                const betBehind = box.player
                                    ? box.player?.betBehinds
                                          .filter(
                                              (b) =>
                                                  b.user_id ===
                                                  gameState.user?.id
                                          )
                                          .reduce(
                                              (acc, cur) =>
                                                  acc + (cur?.amount || 0),
                                              0
                                          )
                                    : 0;
                                return (
                                    <Seat
                                        key={`seat-${box.seat}`}
                                        bets={box.bets}
                                        seat={box.seat}
                                        mode={state}
                                        seated={!!box.player}
                                        selected={currentTurn === box.seat}
                                        ownSeat={box.ownSeat}
                                        isKicked={
                                            gameState.kickedFromSeat.isKicked
                                        }
                                        isPlayerAtSeatLimit={
                                            isPlayerAtSeatLimit
                                        }
                                        position={box.position}
                                        rotation={box.rotation}
                                        onClick={handleClick(box.seat)}
                                        behindAmount={betBehind}
                                        behindWinAmount={
                                            box.player?.state ===
                                            PlayerState.WIN
                                                ? betBehind * 2
                                                : 0
                                        }
                                        betAmounts={box.player?.bets}
                                        winAmounts={box.player?.winAmounts}
                                        insurance={
                                            box.player?.chips?.insurance || 0
                                        }
                                        muted={props.muted}
                                        isMobile={isMobile}
                                        replicateSideBets={replicateSideBets}
                                    >
                                        {box.player && (
                                            <PlayerName
                                                player={{
                                                    lastAction:
                                                        box.player.lastAction,
                                                    lastActions:
                                                        box.player.lastActions,
                                                    lastActionTimes:
                                                        box.player
                                                            .lastActionTimes,
                                                    cards: box.player.cards,
                                                    scores: box.player.scores,
                                                    baseBet:
                                                        box.player.bets.base,
                                                    playerState:
                                                        box.player.state,
                                                    states: box.player.states,
                                                    seat: box.player.seat,
                                                    playerName: box.player.name,
                                                    winStreak:
                                                        box.player.winStreak,
                                                }}
                                                isUserGameLeader={
                                                    gameState.gameLeader ===
                                                    gameState.user?.id
                                                }
                                                kickPlayerhandler={
                                                    handlePlayerKick
                                                }
                                                state={
                                                    gameState.currentState
                                                        .next_state
                                                }
                                                hand={
                                                    gameState.currentState.hand
                                                }
                                                preAction={
                                                    box.ownSeat
                                                        ? gameState.playerPreActions.find(
                                                              (p) =>
                                                                  p.seat_id ===
                                                                  box.seat
                                                          )
                                                        : undefined
                                                }
                                                selected={
                                                    gameState.currentState
                                                        .seat_id === box.seat
                                                }
                                                ownSeat={box.ownSeat}
                                                onClick={() =>
                                                    onPlayerClick(box.seat)
                                                }
                                                handlePlayerAction={
                                                    onPlayerPreAction
                                                }
                                                isMobile={isMobile}
                                            />
                                        )}
                                    </Seat>
                                );
                            })}
                        </group>
                    </Scene>
                    <Preload all />
                </Suspense>
            </Canvas>
        </Container>
    );
};
